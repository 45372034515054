import React from 'react';
import CandleStickSimpleChart from './CandleStickSimple';
import CandleStickChartWithCHMousePointer from './CandleStickChartWithCHMousePointer';
import parseData from './dataParsers'
import { Col } from 'antd';

export default class MainSecurity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: parseData(this.props.chartdata, this.props.dates),
      performance: this.props.performance,
      score: this.props.score,
      main: false,
    };
  }

  render() {
    const perform = this.state.performance;
    const score = Math.round(this.state.score * 100) / 100;

    return (
      <div>
        {(perform && score) &&
        <div>
          <Col>
            <CandleStickChartWithCHMousePointer type={'svg'} data={this.state.data} num_datapoints={this.state.data.length} />
          </Col>
          </div>
        }
        </div>
    );
  }
}
