import React from 'react';
import LineChart from 'react-linechart';
import {Button, Select, Statistic, Col, Progress, Row, Slider} from 'antd';
import ReactApexChart from 'react-apexcharts'
import '../../../../node_modules/react-linechart/dist/styles.css';
const { Option } = Select;

const MAIN_COLOR = '#1890ff'
const GREY_COLOR = '#ecf0f1'

const options = {
  chart: {
    type: 'line'
  },
  colors: ["#1890ff", "#ecf0f1", "#ecf0f1"],

}

function formatter(value) {
  return `${value} Days`;
}

const series = [
  {
    data: [30,40,45,50,49,60,70,91,125]
  },
  {
    data: [32,3,45,50,22,60,70,91,10]
  },
  {
    data: [1,3,3,50,4,60,70,100,38]
  }
];

export default class PerformanceChart extends React.Component {
  constructor(props){
    super(props);
    this.updateHorizon = this.updateHorizon.bind(this);
    this.apexChartParser = this.apexChartParser.bind(this);
    this.state = {
      horizon: 30,
      haystakScore: Math.pow(1 - Math.tanh(this.props.haystakScore), 2),
      series: series,
      options: options,
      chart_data: null,
    }
    console.log(this.state)
  }

  updateHorizon = (horizon) => {
    this.apexChartParser(horizon)
    this.setState({
      horizon: horizon
    })
  }

  apexChartParser(horizon){
    const returns = this.props.returns
    const average = this.props.average
    console.log("apexChartParser")
    console.log(this.state)

    // Update colors
    let colors = average[horizon-1] > 0 ? ['#49C164'] : ['#F3605B']
    for (var i = 0; i < returns.length; i++) {
      colors.push(GREY_COLOR)
    }

    // Updating series
    let series = [{'data': [0].concat(average.slice(0,horizon)),
                    'name': 'Average Returns' },
                  ]
    series.push()
    for (var i = 0; i < returns.length; i++) {
      let tmp_data = [0].concat(returns[i].slice(0,horizon))
      const tmp = {'data': tmp_data,
                    'name': 'Most Similar #' + (i+1)}
      series.push(tmp)
    }

    const options = {
      chart: {
        type: 'line'
      },
      stroke: {
          width: 2,
      },
      tooltip: {
       enabledOnSeries: [0],
       x: {
         formatter: function (value) {
           return "+" + value - 1 + " Days";
         }
       }

     },
      colors: colors,
      legend: {
        show: false,
      },
      xaxis: {
        title: {
           text: 'Days in the future'
         },
         labels: {
           formatter: function (value) {
             return value - 1;
           }
         }
      },
      yaxis: {
        title: {
           text: 'Returns'
         },
        labels: {
        formatter: function (value) {
          return Math.round(value*100*10)/10 + "%";
        }
      },
    },
    }
    console.log(series)
    return [options, series]
  }


  render() {
    const chart_data = this.apexChartParser(this.state.horizon);

    return (
        <div>
            <div className="PerformanceChart">
            <Row >
              <Col span={12}>
                <h4>Following returns of all matches</h4>
              </Col>
              <Col span={12}>
                <Col span={10}>
                  <Statistic
                    value={this.props.average[this.state.horizon-1]*100}
                    precision={2}
                    title={this.state.horizon + "-day avg. return"}
                    suffix="%"
                    tit
                  />
                </Col>
                <Col span={14}>
                  <Statistic
                    value={this.props.positive_split[this.state.horizon-1]*100}
                    precision={2}
                    suffix="%"
                    title={"% Matches positive after " + this.state.horizon + " days"}
                  />
                </Col>
              </Col>
            </Row>
            <Row gutter={40}>
              <Col span={6}>
                  <h4 style={{float:'right'}}>Change date range: </h4>
              </Col>
              <Col span={18}>
                <Slider defaultValue={30} min={1} max={30} onChange={this.updateHorizon} tipFormatter={formatter} />
              </Col>
            </Row>
            {chart_data &&
              <ReactApexChart options={chart_data[0]} series={chart_data[1]} type="line" height="350" />
            }
            <Row type="flex" justify="center">
              <Col span={20}>
                <p>Avg. Haystak Similarity Score</p>
                <Progress
                  strokeColor=
                  {{
                    from: '#108ee9',
                    to: '#87d068',
                  }}
                  percent={this.state.haystakScore.toFixed(2)*100}
                  format={percent => `${percent}/100`}
                  status="active"
                />
              </Col>
          </Row>
        </div>
      </div>
    );
  }
}
