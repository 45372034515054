import React from 'react';
import {Tabs, Icon, Typography, Layout, Divider } from 'antd';
import { withAuthorization } from '../Session';
import TabPage from './TabContents/Tab';
const { Content } = Layout;

const { Title, Text, Paragraph } = Typography;

class Analysis extends React.Component {

  render() {
    const isMobile = window.innerWidth <= 500;

    return (
      <div>
        {isMobile &&
          <div>
            <Layout className="layout">
              <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
                <TabPage />
              </Content>
            </Layout>
          </div>
        }
        {!isMobile &&
          <Layout className="layout">
            <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
              <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
                <TabPage />
              </div>
            </Content>
          </Layout>
        }
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Analysis);
