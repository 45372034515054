import React from 'react';
import { Progress } from 'antd';

export default class HaystakScore extends React.Component{

  render(){
    return(
      <div>
        <p>Haystak Similarity Score</p>
        <Progress
          type="circle"
          width="80px"
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
          strokeWidth={8}
          percent={this.props.score.toFixed(2) * 100}
          format={percent => `${percent}/100`}
        />
      </div>
    )
  }
}
