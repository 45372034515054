import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import './index.css';
import { Form, Icon, Input, Button, Checkbox, Layout, Row, Col} from 'antd';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants/routes';

const { Content } = Layout;

const SignInPage = () =>(
  <Layout>
    <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
      <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>

        <Row justify="center" type="flex">
          <h2>Sign in</h2>
        </Row>

        <Row justify="center" type="flex">
          <h1>to continue to Haystak</h1>
        </Row>

        <br></br>

        <Row justify="center" type="flex">
          <SignInForm />
        </Row>

      </div>
    </Content>
  </Layout>
)

const INITIAL_STATE = {
  email:'',
  password:'',
  error:'null'
};

class SignInFormBase extends React.Component{
  constructor(props){
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.ANALYSIS)
      })
      .catch(error =>{
        this.setState({error});
      });
      event.preventDefault();
  };

  onChange = event =>{
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    return(
      <Form onSubmit={this.onSubmit} className="login-form">
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Enter E-mail"
            allowClear={true}
          />
          <br></br>
          <br></br>
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Enter password"
            allowClear={true}
          />
          <br></br>
          <br></br>
          <a className="login-form-forgot" href="">
            <u><PasswordForgetLink /></u>
            <br></br>
            <br></br>
          </a>
          <Button disable={isInvalid}  type="primary" htmlType="submit" className="login-form-button">
            Sign in
          </Button>
          Or <u><SignUpLink /></u>!

          {error && <p>{error.message}</p>}
      </Form>

    )
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase
)(SignInFormBase);

export default SignInPage;
export {SignInForm}
