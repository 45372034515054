export const dataSource = [{
  title: 'Indices',
  children:
  [
    {
      ticker: 'DJIA',
      title: 'Dow Jones Industrial Average',
      asset: 'EQ'
    },
    {
      ticker: 'NASDAQ',
      title: 'NASDAQ',
      asset: 'EQ'
    },
    {
      ticker: 'SPX',
      title: 'S&P 500',
      asset: 'EQ',
    }
  ],
  }, {
  title: 'Equities',
  children:
  [
    {
      "ticker": "MMM",
      "title": "3M Company",
      "asset": "EQ"
    },
    {
      "ticker": "ABT",
      "title": "Abbott Laboratories",
      "asset": "EQ"
    },
    {
      "ticker": "ADS",
      "title": "Alliance Data Systems",
      "asset": "EQ"
    },
    {
      "ticker": "LNT",
      "title": "Alliant Energy Corp",
      "asset": "EQ"
    },
    {
      "ticker": "ALL",
      "title": "Allstate Corp",
      "asset": "EQ"
    },
    {
      "ticker": "GOOGL",
      "title": "Alphabet Inc Class A",
      "asset": "EQ"
    },
    {
      "ticker": "MO",
      "title": "Altria Group Inc",
      "asset": "EQ"
    },
    {
      "ticker": "AMZN",
      "title": "Amazon.com Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "AMCR",
      "title": "Amcor plc",
      "asset": "EQ"
    },
    {
      "ticker": "AEE",
      "title": "Ameren Corp",
      "asset": "EQ"
    },
    {
      "ticker": "AAL",
      "title": "American Airlines Group",
      "asset": "EQ"
    },
    {
      "ticker": "AEP",
      "title": "American Electric Power",
      "asset": "EQ"
    },
    {
      "ticker": "AXP",
      "title": "American Express Co",
      "asset": "EQ"
    },
    {
      "ticker": "AIG",
      "title": "American International Group",
      "asset": "EQ"
    },
    {
      "ticker": "AMT",
      "title": "American Tower Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "ADI",
      "title": "Analog Devices, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "AAPL",
      "title": "Apple Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "ADSK",
      "title": "Autodesk Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "ADP",
      "title": "Automatic Data Processing",
      "asset": "EQ"
    },
    {
      "ticker": "AZO",
      "title": "AutoZone Inc",
      "asset": "EQ"
    },
    {
      "ticker": "BLL",
      "title": "Ball Corp",
      "asset": "EQ"
    },
    {
      "ticker": "BAC",
      "title": "Bank of America Corp",
      "asset": "EQ"
    },
    {
      "ticker": "BIIB",
      "title": "Biogen Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "BLK",
      "title": "BlackRock",
      "asset": "EQ"
    },
    {
      "ticker": "HRB",
      "title": "Block H&R",
      "asset": "EQ"
    },
    {
      "ticker": "BA",
      "title": "Boeing Company",
      "asset": "EQ"
    },
    {
      "ticker": "BKNG",
      "title": "Booking Holdings Inc",
      "asset": "EQ"
    },
    {
      "ticker": "BWA",
      "title": "BorgWarner",
      "asset": "EQ"
    },
    {
      "ticker": "BXP",
      "title": "Boston Properties",
      "asset": "EQ"
    },
    {
      "ticker": "CDNS",
      "title": "Cadence Design Systems",
      "asset": "EQ"
    },
    {
      "ticker": "CPB",
      "title": "Campbell Soup",
      "asset": "EQ"
    },
    {
      "ticker": "COF",
      "title": "Capital One Financial",
      "asset": "EQ"
    },
    {
      "ticker": "CPRI",
      "title": "Capri Holdings",
      "asset": "EQ"
    },
    {
      "ticker": "CAH",
      "title": "Cardinal Health Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "CAT",
      "title": "Caterpillar Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "CBOE",
      "title": "Cboe Global Markets",
      "asset": "EQ"
    },
    {
      "ticker": "CBRE",
      "title": "CBRE Group",
      "asset": "EQ"
    },
    {
      "ticker": "SCHW",
      "title": "Charles Schwab Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "CVX",
      "title": "Chevron Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "CMG",
      "title": "Chipotle Mexican Grill",
      "asset": "EQ"
    },
    {
      "ticker": "CB",
      "title": "Chubb Limited",
      "asset": "EQ"
    },
    {
      "ticker": "CHD",
      "title": "Church & Dwight",
      "asset": "EQ"
    },
    {
      "ticker": "CI",
      "title": "CIGNA Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "XEC",
      "title": "Cimarex Energy",
      "asset": "EQ"
    },
    {
      "ticker": "CSCO",
      "title": "Cisco Systems",
      "asset": "EQ"
    },
    {
      "ticker": "C",
      "title": "Citigroup Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "CFG",
      "title": "Citizens Financial Group",
      "asset": "EQ"
    },
    {
      "ticker": "CTXS",
      "title": "Citrix Systems",
      "asset": "EQ"
    },
    {
      "ticker": "CLX",
      "title": "The Clorox Company",
      "asset": "EQ"
    },
    {
      "ticker": "CME",
      "title": "CME Group Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "KO",
      "title": "Coca-Cola Company",
      "asset": "EQ"
    },
    {
      "ticker": "DE",
      "title": "Deere & Co.",
      "asset": "EQ"
    },
    {
      "ticker": "DAL",
      "title": "Delta Air Lines Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "XRAY",
      "title": "Dentsply Sirona",
      "asset": "EQ"
    },
    {
      "ticker": "DVN",
      "title": "Devon Energy",
      "asset": "EQ"
    },
    {
      "ticker": "FANG",
      "title": "Diamondback Energy",
      "asset": "EQ"
    },
    {
      "ticker": "DLR",
      "title": "Digital Realty Trust Inc",
      "asset": "EQ"
    },
    {
      "ticker": "DOW",
      "title": "Dow Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "DTE",
      "title": "DTE Energy Co.",
      "asset": "EQ"
    },
    {
      "ticker": "DUK",
      "title": "Duke Energy",
      "asset": "EQ"
    },

    {
      "ticker": "EBAY",
      "title": "eBay Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "EIX",
      "title": "Edison Int'l",
      "asset": "EQ"
    },
    {
      "ticker": "EW",
      "title": "Edwards Lifesciences",
      "asset": "EQ"
    },
    {
      "ticker": "EA",
      "title": "Electronic Arts",
      "asset": "EQ"
    },
    {
      "ticker": "EMR",
      "title": "Emerson Electric Company",
      "asset": "EQ"
    },
    {
      "ticker": "ETR",
      "title": "Entergy Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "EOG",
      "title": "EOG Resources",
      "asset": "EQ"
    },
    {
      "ticker": "EFX",
      "title": "Equifax Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "EL",
      "title": "Estee Lauder Cos.",
      "asset": "EQ"
    },
    {
      "ticker": "EVRG",
      "title": "Evergy",
      "asset": "EQ"
    },
    {
      "ticker": "ES",
      "title": "Eversource Energy",
      "asset": "EQ"
    },
    {
      "ticker": "EXC",
      "title": "Exelon Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "EXPE",
      "title": "Expedia Group",
      "asset": "EQ"
    },
    {
      "ticker": "XOM",
      "title": "Exxon Mobil Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "FFIV",
      "title": "F5 Networks",
      "asset": "EQ"
    },
    {
      "ticker": "FB",
      "title": "Facebook, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "FDX",
      "title": "FedEx Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "FIS",
      "title": "Fidelity National Information Services",
      "asset": "EQ"
    },
    {
      "ticker": "FITB",
      "title": "Fifth Third Bancorp",
      "asset": "EQ"
    },
    {
      "ticker": "FE",
      "title": "FirstEnergy Corp",
      "asset": "EQ"
    },
    {
      "ticker": "FRC",
      "title": "First Republic Bank",
      "asset": "EQ"
    },
    {
      "ticker": "FISV",
      "title": "Fiserv Inc",
      "asset": "EQ"
    },
    {
      "ticker": "FMC",
      "title": "FMC Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "FL",
      "title": "Foot Locker Inc",
      "asset": "EQ"
    },
    {
      "ticker": "F",
      "title": "Ford Motor",
      "asset": "EQ"
    },
    {
      "ticker": "FTNT",
      "title": "Fortinet",
      "asset": "EQ"
    },
    {
      "ticker": "FTV",
      "title": "Fortive Corp",
      "asset": "EQ"
    },
    {
      "ticker": "FOXA",
      "title": "Fox Corporation Class A",
      "asset": "EQ"
    },
    {
      "ticker": "FOX",
      "title": "Fox Corporation Class B",
      "asset": "EQ"
    },
    {
      "ticker": "GPS",
      "title": "Gap Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "GRMN",
      "title": "Garmin Ltd.",
      "asset": "EQ"
    },
    {
      "ticker": "GE",
      "title": "General Electric",
      "asset": "EQ"
    },
    {
      "ticker": "GIS",
      "title": "General Mills",
      "asset": "EQ"
    },
    {
      "ticker": "GM",
      "title": "General Motors",
      "asset": "EQ"
    },
    {
      "ticker": "GPN",
      "title": "Global Payments Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "GS",
      "title": "Goldman Sachs Group",
      "asset": "EQ"
    },
    {
      "ticker": "HAL",
      "title": "Halliburton Co.",
      "asset": "EQ"
    },
    {
      "ticker": "HOG",
      "title": "Harley-Davidson",
      "asset": "EQ"
    },
    {
      "ticker": "HAS",
      "title": "Hasbro Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "HSY",
      "title": "The Hershey Company",
      "asset": "EQ"
    },
    {
      "ticker": "HES",
      "title": "Hess Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "HPE",
      "title": "Hewlett Packard Enterprise",
      "asset": "EQ"
    },
    {
      "ticker": "HLT",
      "title": "Hilton Worldwide Holdings Inc",
      "asset": "EQ"
    },
    {
      "ticker": "HFC",
      "title": "HollyFrontier Corp",
      "asset": "EQ"
    },
    {
      "ticker": "HOLX",
      "title": "Hologic",
      "asset": "EQ"
    },
    {
      "ticker": "HD",
      "title": "Home Depot",
      "asset": "EQ"
    },
    {
      "ticker": "HON",
      "title": "Honeywell Int'l Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "HRL",
      "title": "Hormel Foods Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "HST",
      "title": "Host Hotels & Resorts",
      "asset": "EQ"
    },
    {
      "ticker": "HPQ",
      "title": "HP Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "HUM",
      "title": "Humana Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "HBAN",
      "title": "Huntington Bancshares",
      "asset": "EQ"
    },
    {
      "ticker": "HII",
      "title": "Huntington Ingalls Industries",
      "asset": "EQ"
    },
    {
      "ticker": "IDXX",
      "title": "IDEXX Laboratories",
      "asset": "EQ"
    },
    {
      "ticker": "INFO",
      "title": "IHS Markit Ltd.",
      "asset": "EQ"
    },
    {
      "ticker": "ILMN",
      "title": "Illumina Inc",
      "asset": "EQ"
    },
    {
      "ticker": "IR",
      "title": "Ingersoll-Rand PLC",
      "asset": "EQ"
    },
    {
      "ticker": "INTC",
      "title": "Intel Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "ICE",
      "title": "Intercontinental Exchange",
      "asset": "EQ"
    },
    {
      "ticker": "IBM",
      "title": "International Business Machines",
      "asset": "EQ"
    },
    {
      "ticker": "IVZ",
      "title": "Invesco Ltd.",
      "asset": "EQ"
    },
    {
      "ticker": "IPGP",
      "title": "IPG Photonics Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "IQV",
      "title": "IQVIA Holdings Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "IRM",
      "title": "Iron Mountain Incorporated",
      "asset": "EQ"
    },
    {
      "ticker": "JKHY",
      "title": "Jack Henry & Associates",
      "asset": "EQ"
    },
    {
      "ticker": "JEC",
      "title": "Jacobs Engineering Group",
      "asset": "EQ"
    },
    {
      "ticker": "JEF",
      "title": "Jefferies Financial Group",
      "asset": "EQ"
    },
    {
      "ticker": "JNJ",
      "title": "Johnson & Johnson",
      "asset": "EQ"
    },
    {
      "ticker": "JCI",
      "title": "Johnson Controls International",
      "asset": "EQ"
    },
    {
      "ticker": "JPM",
      "title": "JPMorgan Chase & Co.",
      "asset": "EQ"
    },
    {
      "ticker": "JNPR",
      "title": "Juniper Networks",
      "asset": "EQ"
    },
    {
      "ticker": "K",
      "title": "Kellogg Co.",
      "asset": "EQ"
    },
    {
      "ticker": "KHC",
      "title": "Kraft Heinz Co",
      "asset": "EQ"
    },
    {
      "ticker": "LIN",
      "title": "Linde plc",
      "asset": "EQ"
    },
    {
      "ticker": "LMT",
      "title": "Lockheed Martin Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "MKTX",
      "title": "MarketAxess",
      "asset": "EQ"
    },
    {
      "ticker": "MAR",
      "title": "Marriott Int'l.",
      "asset": "EQ"
    },
    {
      "ticker": "MAS",
      "title": "Masco Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "MA",
      "title": "Mastercard Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "MXIM",
      "title": "Maxim Integrated Products Inc",
      "asset": "EQ"
    },
    {
      "ticker": "MCD",
      "title": "McDonald's Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "MRK",
      "title": "Merck & Co.",
      "asset": "EQ"
    },
    {
      "ticker": "MET",
      "title": "MetLife Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "MTD",
      "title": "Mettler Toledo",
      "asset": "EQ"
    },
    {
      "ticker": "MGM",
      "title": "MGM Resorts International",
      "asset": "EQ"
    },
    {
      "ticker": "MCHP",
      "title": "Microchip Technology",
      "asset": "EQ"
    },
    {
      "ticker": "MU",
      "title": "Micron Technology",
      "asset": "EQ"
    },
    {
      "ticker": "MSFT",
      "title": "Microsoft Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "MDLZ",
      "title": "Mondelez International",
      "asset": "EQ"
    },
    {
      "ticker": "MNST",
      "title": "Monster Beverage",
      "asset": "EQ"
    },
    {
      "ticker": "MCO",
      "title": "Moody's Corp",
      "asset": "EQ"
    },
    {
      "ticker": "MS",
      "title": "Morgan Stanley",
      "asset": "EQ"
    },
    {
      "ticker": "MSI",
      "title": "Motorola Solutions Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "MSCI",
      "title": "MSCI Inc",
      "asset": "EQ"
    },
    {
      "ticker": "MYL",
      "title": "Mylan N.V.",
      "asset": "EQ"
    },
    {
      "ticker": "NDAQ",
      "title": "Nasdaq, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "NFLX",
      "title": "Netflix Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "NKE",
      "title": "Nike",
      "asset": "EQ"
    },
    {
      "ticker": "NSC",
      "title": "Norfolk Southern Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "NTRS",
      "title": "Northern Trust Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "NOC",
      "title": "Northrop Grumman",
      "asset": "EQ"
    },
    {
      "ticker": "NCLH",
      "title": "Norwegian Cruise Line Holdings",
      "asset": "EQ"
    },
    {
      "ticker": "NRG",
      "title": "NRG Energy",
      "asset": "EQ"
    },
    {
      "ticker": "NVDA",
      "title": "Nvidia Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "ORCL",
      "title": "Oracle Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "PH",
      "title": "Parker-Hannifin",
      "asset": "EQ"
    },
    {
      "ticker": "PAYX",
      "title": "Paychex Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "PYPL",
      "title": "PayPal",
      "asset": "EQ"
    },
    {
      "ticker": "PEP",
      "title": "PepsiCo Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "PFE",
      "title": "Pfizer Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "PM",
      "title": "Philip Morris International",
      "asset": "EQ"
    },
    {
      "ticker": "PSX",
      "title": "Phillips 66",
      "asset": "EQ"
    },
    {
      "ticker": "PNW",
      "title": "Pinnacle West Capital",
      "asset": "EQ"
    },
    {
      "ticker": "PNC",
      "title": "PNC Financial Services",
      "asset": "EQ"
    },
    {
      "ticker": "PPG",
      "title": "PPG Industries",
      "asset": "EQ"
    },
    {
      "ticker": "PPL",
      "title": "PPL Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "PFG",
      "title": "Principal Financial Group",
      "asset": "EQ"
    },
    {
      "ticker": "PG",
      "title": "Procter & Gamble",
      "asset": "EQ"
    },
    {
      "ticker": "PWR",
      "title": "Quanta Services Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "QCOM",
      "title": "QUALCOMM Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "DGX",
      "title": "Quest Diagnostics",
      "asset": "EQ"
    },
    {
      "ticker": "RL",
      "title": "Ralph Lauren Corporation",
      "asset": "EQ"
    },
    {
      "ticker": "ROK",
      "title": "Rockwell Automation Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "ROL",
      "title": "Rollins Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "ROP",
      "title": "Roper Technologies",
      "asset": "EQ"
    },
    {
      "ticker": "ROST",
      "title": "Ross Stores",
      "asset": "EQ"
    },
    {
      "ticker": "CRM",
      "title": "Salesforce.com",
      "asset": "EQ"
    },
    {
      "ticker": "SLB",
      "title": "Schlumberger Ltd.",
      "asset": "EQ"
    },
    {
      "ticker": "LUV",
      "title": "Southwest Airlines",
      "asset": "EQ"
    },
    {
      "ticker": "SPGI",
      "title": "S&P Global, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "SBUX",
      "title": "Starbucks Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "STT",
      "title": "State Street Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "SNPS",
      "title": "Synopsys Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "SYY",
      "title": "Sysco Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "TMUS",
      "title": "T-Mobile US",
      "asset": "EQ"
    },
    {
      "ticker": "TROW",
      "title": "T. Rowe Price Group",
      "asset": "EQ"
    },
    {
      "ticker": "FTI",
      "title": "TechnipFMC",
      "asset": "EQ"
    },
    {
      "ticker": "TFX",
      "title": "Teleflex",
      "asset": "EQ"
    },
    {
      "ticker": "TXN",
      "title": "Texas Instruments",
      "asset": "EQ"
    },
    {
      "ticker": "TXT",
      "title": "Textron Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "TMO",
      "title": "Thermo Fisher Scientific",
      "asset": "EQ"
    },
    {
      "ticker": "TIF",
      "title": "Tiffany & Co.",
      "asset": "EQ"
    },
    {
      "ticker": "TWTR",
      "title": "Twitter, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "UAA",
      "title": "Under Armour Class A",
      "asset": "EQ"
    },
    {
      "ticker": "UAL",
      "title": "United Airlines Holdings",
      "asset": "EQ"
    },
    {
      "ticker": "UPS",
      "title": "United Parcel Service",
      "asset": "EQ"
    },
    {
      "ticker": "URI",
      "title": "United Rentals, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "UTX",
      "title": "United Technologies",
      "asset": "EQ"
    },
    {
      "ticker": "UHS",
      "title": "Universal Health Services, Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "UNM",
      "title": "Unum Group",
      "asset": "EQ"
    },
    {
      "ticker": "VFC",
      "title": "V.F. Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "VLO",
      "title": "Valero Energy",
      "asset": "EQ"
    },
    {
      "ticker": "VZ",
      "title": "Verizon Communications",
      "asset": "EQ"
    },
    {
      "ticker": "WMT",
      "title": "Walmart",
      "asset": "EQ"
    },
    {
      "ticker": "WBA",
      "title": "Walgreens Boots Alliance",
      "asset": "EQ"
    },
    {
      "ticker": "DIS",
      "title": "The Walt Disney Company",
      "asset": "EQ"
    },
    {
      "ticker": "WFC",
      "title": "Wells Fargo",
      "asset": "EQ"
    },
    {
      "ticker": "WELL",
      "title": "Welltower Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "WDC",
      "title": "Western Digital",
      "asset": "EQ"
    },
    {
      "ticker": "WU",
      "title": "Western Union Co",
      "asset": "EQ"
    },
    {
      "ticker": "WLTW",
      "title": "Willis Towers Watson",
      "asset": "EQ"
    },
  ],
  }, {
  title: 'FX',
  children:
  [
    {
      ticker: 'EURUSD',
      title: 'EUR/USD',
      asset: 'FX',
    },
    {
      ticker: 'GBPUSD',
      title: 'GBP/USD',
      asset: 'FX',
    },
    {
      ticker: 'USDCNY',
      title: 'USD/CNY Spot',
      asset: 'FX',
    },
    {
      ticker: 'USDJPY',
      title: 'USD/JPY',
      asset: 'FX',
    },
    {
      ticker: 'USDTRY',
      title: 'USD/TRY',
      asset: 'FX',
    },
    {
      ticker: 'USDZAR',
      title: 'USD/ZAR',
      asset: 'FX'
    }
  ],
  }, {
  title: 'Cryptocurrencies',
  children:
  [
    {
      ticker: 'USDBTC',
      title: 'USD/BTC Bitcoin',
      asset: 'Crypto',
    },
    {
      ticker: 'USDETH',
      title: 'USD/ETH Ethereum',
      asset: 'Crypto',
    }
  ],
}];

// export const dataSource = [{
//   title: 'Indices',
//   children:
//   [
//     {
//       ticker: 'DJIA',
//       title: 'Dow Jones Industrial Average',
//       asset: 'EQ'
//     },
//     {
//       ticker: 'NASDAQ',
//       title: 'NASDAQ',
//       asset: 'EQ'
//     },
//     {
//       ticker: 'SPX',
//       title: 'S&P 500',
//       asset: 'EQ',
//     }
//   ],
//   }, {
//   title: 'Equities',
//   children:
//   [
//     {
//       "ticker": "MMM",
//       "title": "3M Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ABT",
//       "title": "Abbott Laboratories",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ABBV",
//       "title": "AbbVie Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ABMD",
//       "title": "ABIOMED Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ACN",
//       "title": "Accenture plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ATVI",
//       "title": "Activision Blizzard",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADBE",
//       "title": "Adobe Systems Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMD",
//       "title": "Advanced Micro Devices Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AAP",
//       "title": "Advance Auto Parts",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AES",
//       "title": "AES Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMG",
//       "title": "Affiliated Managers Group Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AFL",
//       "title": "AFLAC Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "A",
//       "title": "Agilent Technologies Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "APD",
//       "title": "Air Products & Chemicals Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AKAM",
//       "title": "Akamai Technologies Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALK",
//       "title": "Alaska Air Group Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALB",
//       "title": "Albemarle Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ARE",
//       "title": "Alexandria Real Estate Equities",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALXN",
//       "title": "Alexion Pharmaceuticals",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALGN",
//       "title": "Align Technology",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALLE",
//       "title": "Allegion",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AGN",
//       "title": "Allergan, Plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADS",
//       "title": "Alliance Data Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LNT",
//       "title": "Alliant Energy Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ALL",
//       "title": "Allstate Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GOOGL",
//       "title": "Alphabet Inc Class A",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GOOG",
//       "title": "Alphabet Inc Class C",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MO",
//       "title": "Altria Group Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMZN",
//       "title": "Amazon.com Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMCR",
//       "title": "Amcor plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AEE",
//       "title": "Ameren Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AAL",
//       "title": "American Airlines Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AEP",
//       "title": "American Electric Power",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AXP",
//       "title": "American Express Co",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AIG",
//       "title": "American International Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMT",
//       "title": "American Tower Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AWK",
//       "title": "American Water Works Company Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMP",
//       "title": "Ameriprise Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ABC",
//       "title": "AmerisourceBergen Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AME",
//       "title": "AMETEK Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMGN",
//       "title": "Amgen Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "APH",
//       "title": "Amphenol Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "APC",
//       "title": "Anadarko Petroleum Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADI",
//       "title": "Analog Devices, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ANSS",
//       "title": "ANSYS",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ANTM",
//       "title": "Anthem",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AON",
//       "title": "Aon plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AOS",
//       "title": "A.O. Smith Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "APA",
//       "title": "Apache Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AIV",
//       "title": "Apartment Investment & Management",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AAPL",
//       "title": "Apple Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AMAT",
//       "title": "Applied Materials Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "APTV",
//       "title": "Aptiv Plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADM",
//       "title": "Archer-Daniels-Midland Co",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ARNC",
//       "title": "Arconic Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ANET",
//       "title": "Arista Networks",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AJG",
//       "title": "Arthur J. Gallagher & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AIZ",
//       "title": "Assurant",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ATO",
//       "title": "Atmos Energy Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "T",
//       "title": "AT&T Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADSK",
//       "title": "Autodesk Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ADP",
//       "title": "Automatic Data Processing",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AZO",
//       "title": "AutoZone Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AVB",
//       "title": "AvalonBay Communities, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AVY",
//       "title": "Avery Dennison Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BHGE",
//       "title": "Baker Hughes, a GE Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BLL",
//       "title": "Ball Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BAC",
//       "title": "Bank of America Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BK",
//       "title": "The Bank of New York Mellon Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BAX",
//       "title": "Baxter International Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BBT",
//       "title": "BB&T Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BDX",
//       "title": "Becton Dickinson",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BRK.B",
//       "title": "Berkshire Hathaway",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BBY",
//       "title": "Best Buy Co. Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BIIB",
//       "title": "Biogen Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BLK",
//       "title": "BlackRock",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HRB",
//       "title": "Block H&R",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BA",
//       "title": "Boeing Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BKNG",
//       "title": "Booking Holdings Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BWA",
//       "title": "BorgWarner",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BXP",
//       "title": "Boston Properties",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BSX",
//       "title": "Boston Scientific",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BMY",
//       "title": "Bristol-Myers Squibb",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "AVGO",
//       "title": "Broadcom Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BR",
//       "title": "Broadridge Financial Solutions",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BF.B",
//       "title": "Brown-Forman Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CHRW",
//       "title": "C. H. Robinson Worldwide",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COG",
//       "title": "Cabot Oil & Gas",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CDNS",
//       "title": "Cadence Design Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CPB",
//       "title": "Campbell Soup",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COF",
//       "title": "Capital One Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CPRI",
//       "title": "Capri Holdings",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CAH",
//       "title": "Cardinal Health Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KMX",
//       "title": "Carmax Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CCL",
//       "title": "Carnival Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CAT",
//       "title": "Caterpillar Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CBOE",
//       "title": "Cboe Global Markets",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CBRE",
//       "title": "CBRE Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CBS",
//       "title": "CBS Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CE",
//       "title": "Celanese",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CELG",
//       "title": "Celgene Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CNC",
//       "title": "Centene Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CNP",
//       "title": "CenterPoint Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CTL",
//       "title": "CenturyLink Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CERN",
//       "title": "Cerner",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CF",
//       "title": "CF Industries Holdings Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SCHW",
//       "title": "Charles Schwab Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CHTR",
//       "title": "Charter Communications",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CVX",
//       "title": "Chevron Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CMG",
//       "title": "Chipotle Mexican Grill",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CB",
//       "title": "Chubb Limited",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CHD",
//       "title": "Church & Dwight",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CI",
//       "title": "CIGNA Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XEC",
//       "title": "Cimarex Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CINF",
//       "title": "Cincinnati Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CTAS",
//       "title": "Cintas Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CSCO",
//       "title": "Cisco Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "C",
//       "title": "Citigroup Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CFG",
//       "title": "Citizens Financial Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CTXS",
//       "title": "Citrix Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CLX",
//       "title": "The Clorox Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CME",
//       "title": "CME Group Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CMS",
//       "title": "CMS Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KO",
//       "title": "Coca-Cola Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CTSH",
//       "title": "Cognizant Technology Solutions",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CL",
//       "title": "Colgate-Palmolive",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CMCSA",
//       "title": "Comcast Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CMA",
//       "title": "Comerica Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CAG",
//       "title": "Conagra Brands",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CXO",
//       "title": "Concho Resources",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COP",
//       "title": "ConocoPhillips",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ED",
//       "title": "Consolidated Edison",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "STZ",
//       "title": "Constellation Brands",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COO",
//       "title": "The Cooper Companies",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CPRT",
//       "title": "Copart Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GLW",
//       "title": "Corning Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CTVA",
//       "title": "Corteva",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COST",
//       "title": "Costco Wholesale Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "COTY",
//       "title": "Coty, Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CCI",
//       "title": "Crown Castle International Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CSX",
//       "title": "CSX Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CMI",
//       "title": "Cummins Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CVS",
//       "title": "CVS Health",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DHI",
//       "title": "D. R. Horton",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DHR",
//       "title": "Danaher Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DRI",
//       "title": "Darden Restaurants",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DVA",
//       "title": "DaVita Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DE",
//       "title": "Deere & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DAL",
//       "title": "Delta Air Lines Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XRAY",
//       "title": "Dentsply Sirona",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DVN",
//       "title": "Devon Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FANG",
//       "title": "Diamondback Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DLR",
//       "title": "Digital Realty Trust Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DFS",
//       "title": "Discover Financial Services",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DISCA",
//       "title": "Discovery Inc. Class A",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DISCK",
//       "title": "Discovery Inc. Class C",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DISH",
//       "title": "Dish Network",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DG",
//       "title": "Dollar General",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DLTR",
//       "title": "Dollar Tree",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "D",
//       "title": "Dominion Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DOV",
//       "title": "Dover Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DOW",
//       "title": "Dow Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DTE",
//       "title": "DTE Energy Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DUK",
//       "title": "Duke Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DRE",
//       "title": "Duke Realty Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DD",
//       "title": "DuPont de Nemours Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DXC",
//       "title": "DXC Technology",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ETFC",
//       "title": "E*Trade",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EMN",
//       "title": "Eastman Chemical",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ETN",
//       "title": "Eaton Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EBAY",
//       "title": "eBay Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ECL",
//       "title": "Ecolab Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EIX",
//       "title": "Edison Int'l",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EW",
//       "title": "Edwards Lifesciences",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EA",
//       "title": "Electronic Arts",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EMR",
//       "title": "Emerson Electric Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ETR",
//       "title": "Entergy Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EOG",
//       "title": "EOG Resources",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EFX",
//       "title": "Equifax Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EQIX",
//       "title": "Equinix",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EQR",
//       "title": "Equity Residential",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ESS",
//       "title": "Essex Property Trust, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EL",
//       "title": "Estee Lauder Cos.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EVRG",
//       "title": "Evergy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ES",
//       "title": "Eversource Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RE",
//       "title": "Everest Re Group Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EXC",
//       "title": "Exelon Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EXPE",
//       "title": "Expedia Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EXPD",
//       "title": "Expeditors",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "EXR",
//       "title": "Extra Space Storage",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XOM",
//       "title": "Exxon Mobil Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FFIV",
//       "title": "F5 Networks",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FB",
//       "title": "Facebook, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FAST",
//       "title": "Fastenal Co",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FRT",
//       "title": "Federal Realty Investment Trust",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FDX",
//       "title": "FedEx Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FIS",
//       "title": "Fidelity National Information Services",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FITB",
//       "title": "Fifth Third Bancorp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FE",
//       "title": "FirstEnergy Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FRC",
//       "title": "First Republic Bank",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FISV",
//       "title": "Fiserv Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FLT",
//       "title": "FleetCor Technologies Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FLIR",
//       "title": "FLIR Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FLS",
//       "title": "Flowserve Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FMC",
//       "title": "FMC Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FL",
//       "title": "Foot Locker Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "F",
//       "title": "Ford Motor",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FTNT",
//       "title": "Fortinet",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FTV",
//       "title": "Fortive Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FBHS",
//       "title": "Fortune Brands Home & Security",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FOXA",
//       "title": "Fox Corporation Class A",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FOX",
//       "title": "Fox Corporation Class B",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "BEN",
//       "title": "Franklin Resources",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FCX",
//       "title": "Freeport-McMoRan Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GPS",
//       "title": "Gap Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GRMN",
//       "title": "Garmin Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IT",
//       "title": "Gartner Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GD",
//       "title": "General Dynamics",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GE",
//       "title": "General Electric",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GIS",
//       "title": "General Mills",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GM",
//       "title": "General Motors",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GPC",
//       "title": "Genuine Parts",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GILD",
//       "title": "Gilead Sciences",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GPN",
//       "title": "Global Payments Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GS",
//       "title": "Goldman Sachs Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "GWW",
//       "title": "Grainger (W.W.) Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HAL",
//       "title": "Halliburton Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HBI",
//       "title": "Hanesbrands Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HOG",
//       "title": "Harley-Davidson",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HIG",
//       "title": "Hartford Financial Svc.Gp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HAS",
//       "title": "Hasbro Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HCA",
//       "title": "HCA Healthcare",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HCP",
//       "title": "HCP Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HP",
//       "title": "Helmerich & Payne",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HSIC",
//       "title": "Henry Schein",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HSY",
//       "title": "The Hershey Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HES",
//       "title": "Hess Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HPE",
//       "title": "Hewlett Packard Enterprise",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HLT",
//       "title": "Hilton Worldwide Holdings Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HFC",
//       "title": "HollyFrontier Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HOLX",
//       "title": "Hologic",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HD",
//       "title": "Home Depot",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HON",
//       "title": "Honeywell Int'l Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HRL",
//       "title": "Hormel Foods Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HST",
//       "title": "Host Hotels & Resorts",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HPQ",
//       "title": "HP Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HUM",
//       "title": "Humana Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HBAN",
//       "title": "Huntington Bancshares",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "HII",
//       "title": "Huntington Ingalls Industries",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IDXX",
//       "title": "IDEXX Laboratories",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "INFO",
//       "title": "IHS Markit Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ITW",
//       "title": "Illinois Tool Works",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ILMN",
//       "title": "Illumina Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IR",
//       "title": "Ingersoll-Rand PLC",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "INTC",
//       "title": "Intel Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ICE",
//       "title": "Intercontinental Exchange",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IBM",
//       "title": "International Business Machines",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "INCY",
//       "title": "Incyte",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IP",
//       "title": "International Paper",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IPG",
//       "title": "Interpublic Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IFF",
//       "title": "Intl Flavors & Fragrances",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "INTU",
//       "title": "Intuit Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ISRG",
//       "title": "Intuitive Surgical Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IVZ",
//       "title": "Invesco Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IPGP",
//       "title": "IPG Photonics Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IQV",
//       "title": "IQVIA Holdings Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "IRM",
//       "title": "Iron Mountain Incorporated",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JKHY",
//       "title": "Jack Henry & Associates",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JEC",
//       "title": "Jacobs Engineering Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JBHT",
//       "title": "J. B. Hunt Transport Services",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JEF",
//       "title": "Jefferies Financial Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SJM",
//       "title": "JM Smucker",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JNJ",
//       "title": "Johnson & Johnson",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JCI",
//       "title": "Johnson Controls International",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JPM",
//       "title": "JPMorgan Chase & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JNPR",
//       "title": "Juniper Networks",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KSU",
//       "title": "Kansas City Southern",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "K",
//       "title": "Kellogg Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KEY",
//       "title": "KeyCorp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KEYS",
//       "title": "Keysight Technologies",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KMB",
//       "title": "Kimberly-Clark",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KIM",
//       "title": "Kimco Realty",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KMI",
//       "title": "Kinder Morgan",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KLAC",
//       "title": "KLA Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KSS",
//       "title": "Kohl's Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KHC",
//       "title": "Kraft Heinz Co",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "KR",
//       "title": "Kroger Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LB",
//       "title": "L Brands Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LHX",
//       "title": "L3Harris Technologies",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LH",
//       "title": "Laboratory Corp. of America Holding",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LRCX",
//       "title": "Lam Research",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LW",
//       "title": "Lamb Weston Holdings Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LEG",
//       "title": "Leggett & Platt",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LEN",
//       "title": "Lennar Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LLY",
//       "title": "Lilly (Eli) & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LNC",
//       "title": "Lincoln National",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LIN",
//       "title": "Linde plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LKQ",
//       "title": "LKQ Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LMT",
//       "title": "Lockheed Martin Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "L",
//       "title": "Loews Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LOW",
//       "title": "Lowe's Cos.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LYB",
//       "title": "LyondellBasell",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MTB",
//       "title": "M&T Bank Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MAC",
//       "title": "Macerich",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "M",
//       "title": "Macy's Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MRO",
//       "title": "Marathon Oil Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MPC",
//       "title": "Marathon Petroleum",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MKTX",
//       "title": "MarketAxess",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MAR",
//       "title": "Marriott Int'l.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MMC",
//       "title": "Marsh & McLennan",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MLM",
//       "title": "Martin Marietta Materials",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MAS",
//       "title": "Masco Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MA",
//       "title": "Mastercard Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MKC",
//       "title": "McCormick & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MXIM",
//       "title": "Maxim Integrated Products Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MCD",
//       "title": "McDonald's Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MCK",
//       "title": "McKesson Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MDT",
//       "title": "Medtronic plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MRK",
//       "title": "Merck & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MET",
//       "title": "MetLife Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MTD",
//       "title": "Mettler Toledo",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MGM",
//       "title": "MGM Resorts International",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MCHP",
//       "title": "Microchip Technology",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MU",
//       "title": "Micron Technology",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MSFT",
//       "title": "Microsoft Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MAA",
//       "title": "Mid-America Apartments",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MHK",
//       "title": "Mohawk Industries",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TAP",
//       "title": "Molson Coors Brewing Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MDLZ",
//       "title": "Mondelez International",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MNST",
//       "title": "Monster Beverage",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MCO",
//       "title": "Moody's Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MS",
//       "title": "Morgan Stanley",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MOS",
//       "title": "The Mosaic Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MSI",
//       "title": "Motorola Solutions Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MSCI",
//       "title": "MSCI Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "MYL",
//       "title": "Mylan N.V.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NDAQ",
//       "title": "Nasdaq, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NOV",
//       "title": "National Oilwell Varco Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NKTR",
//       "title": "Nektar Therapeutics",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NTAP",
//       "title": "NetApp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NFLX",
//       "title": "Netflix Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NWL",
//       "title": "Newell Brands",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NEM",
//       "title": "Newmont Goldcorp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NWSA",
//       "title": "News Corp. Class A",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NWS",
//       "title": "News Corp. Class B",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NEE",
//       "title": "NextEra Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NLSN",
//       "title": "Nielsen Holdings",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NKE",
//       "title": "Nike",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NI",
//       "title": "NiSource Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NBL",
//       "title": "Noble Energy Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "JWN",
//       "title": "Nordstrom",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NSC",
//       "title": "Norfolk Southern Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NTRS",
//       "title": "Northern Trust Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NOC",
//       "title": "Northrop Grumman",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NCLH",
//       "title": "Norwegian Cruise Line Holdings",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NRG",
//       "title": "NRG Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NUE",
//       "title": "Nucor Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "NVDA",
//       "title": "Nvidia Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ORLY",
//       "title": "O'Reilly Automotive",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "OXY",
//       "title": "Occidental Petroleum",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "OMC",
//       "title": "Omnicom Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "OKE",
//       "title": "ONEOK",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ORCL",
//       "title": "Oracle Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PCAR",
//       "title": "PACCAR Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PKG",
//       "title": "Packaging Corporation of America",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PH",
//       "title": "Parker-Hannifin",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PAYX",
//       "title": "Paychex Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PYPL",
//       "title": "PayPal",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PNR",
//       "title": "Pentair plc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PBCT",
//       "title": "People's United Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PEP",
//       "title": "PepsiCo Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PKI",
//       "title": "PerkinElmer",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PRGO",
//       "title": "Perrigo",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PFE",
//       "title": "Pfizer Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PM",
//       "title": "Philip Morris International",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PSX",
//       "title": "Phillips 66",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PNW",
//       "title": "Pinnacle West Capital",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PXD",
//       "title": "Pioneer Natural Resources",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PNC",
//       "title": "PNC Financial Services",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PPG",
//       "title": "PPG Industries",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PPL",
//       "title": "PPL Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PFG",
//       "title": "Principal Financial Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PG",
//       "title": "Procter & Gamble",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PGR",
//       "title": "Progressive Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PLD",
//       "title": "Prologis",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PRU",
//       "title": "Prudential Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PEG",
//       "title": "Public Serv. Enterprise Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PSA",
//       "title": "Public Storage",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PHM",
//       "title": "Pulte Homes Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PVH",
//       "title": "PVH Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "QRVO",
//       "title": "Qorvo",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "PWR",
//       "title": "Quanta Services Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "QCOM",
//       "title": "QUALCOMM Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DGX",
//       "title": "Quest Diagnostics",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RL",
//       "title": "Ralph Lauren Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RJF",
//       "title": "Raymond James Financial Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RTN",
//       "title": "Raytheon Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "O",
//       "title": "Realty Income Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "REG",
//       "title": "Regency Centers Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "REGN",
//       "title": "Regeneron Pharmaceuticals",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RF",
//       "title": "Regions Financial Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RSG",
//       "title": "Republic Services Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RMD",
//       "title": "ResMed",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RHI",
//       "title": "Robert Half International",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ROK",
//       "title": "Rockwell Automation Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ROL",
//       "title": "Rollins Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ROP",
//       "title": "Roper Technologies",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ROST",
//       "title": "Ross Stores",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "RCL",
//       "title": "Royal Caribbean Cruises Ltd",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "CRM",
//       "title": "Salesforce.com",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SBAC",
//       "title": "SBA Communications",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SLB",
//       "title": "Schlumberger Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "STX",
//       "title": "Seagate Technology",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SEE",
//       "title": "Sealed Air",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SRE",
//       "title": "Sempra Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SHW",
//       "title": "Sherwin-Williams",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SPG",
//       "title": "Simon Property Group Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SWKS",
//       "title": "Skyworks Solutions",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SLG",
//       "title": "SL Green Realty",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SNA",
//       "title": "Snap-on",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SO",
//       "title": "Southern Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "LUV",
//       "title": "Southwest Airlines",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SPGI",
//       "title": "S&P Global, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SWK",
//       "title": "Stanley Black & Decker",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SBUX",
//       "title": "Starbucks Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "STT",
//       "title": "State Street Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SYK",
//       "title": "Stryker Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "STI",
//       "title": "SunTrust Banks",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SIVB",
//       "title": "SVB Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SYMC",
//       "title": "Symantec Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SYF",
//       "title": "Synchrony Financial",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SNPS",
//       "title": "Synopsys Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "SYY",
//       "title": "Sysco Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TMUS",
//       "title": "T-Mobile US",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TROW",
//       "title": "T. Rowe Price Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TTWO",
//       "title": "Take-Two Interactive",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TPR",
//       "title": "Tapestry, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TGT",
//       "title": "Target Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TEL",
//       "title": "TE Connectivity Ltd.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "FTI",
//       "title": "TechnipFMC",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TFX",
//       "title": "Teleflex",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TXN",
//       "title": "Texas Instruments",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TXT",
//       "title": "Textron Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TMO",
//       "title": "Thermo Fisher Scientific",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TIF",
//       "title": "Tiffany & Co.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TWTR",
//       "title": "Twitter, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TJX",
//       "title": "TJX Companies Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TMK",
//       "title": "Torchmark Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TSS",
//       "title": "Total System Services",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TSCO",
//       "title": "Tractor Supply Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TDG",
//       "title": "TransDigm Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TRV",
//       "title": "The Travelers Companies Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TRIP",
//       "title": "TripAdvisor",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "TSN",
//       "title": "Tyson Foods",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UDR",
//       "title": "UDR, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ULTA",
//       "title": "Ulta Beauty",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "USB",
//       "title": "U.S. Bancorp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UAA",
//       "title": "Under Armour Class A",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UA",
//       "title": "Under Armour Class C",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UNP",
//       "title": "Union Pacific Corp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UAL",
//       "title": "United Airlines Holdings",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UNH",
//       "title": "United Health Group Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UPS",
//       "title": "United Parcel Service",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "URI",
//       "title": "United Rentals, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UTX",
//       "title": "United Technologies",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UHS",
//       "title": "Universal Health Services, Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "UNM",
//       "title": "Unum Group",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VFC",
//       "title": "V.F. Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VLO",
//       "title": "Valero Energy",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VAR",
//       "title": "Varian Medical Systems",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VTR",
//       "title": "Ventas Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VRSN",
//       "title": "Verisign Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VRSK",
//       "title": "Verisk Analytics",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VZ",
//       "title": "Verizon Communications",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VRTX",
//       "title": "Vertex Pharmaceuticals Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VIAB",
//       "title": "Viacom Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "V",
//       "title": "Visa Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VNO",
//       "title": "Vornado Realty Trust",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "VMC",
//       "title": "Vulcan Materials",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WAB",
//       "title": "Wabtec Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WMT",
//       "title": "Walmart",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WBA",
//       "title": "Walgreens Boots Alliance",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "DIS",
//       "title": "The Walt Disney Company",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WM",
//       "title": "Waste Management Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WAT",
//       "title": "Waters Corporation",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WEC",
//       "title": "Wec Energy Group Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WCG",
//       "title": "WellCare",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WFC",
//       "title": "Wells Fargo",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WELL",
//       "title": "Welltower Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WDC",
//       "title": "Western Digital",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WU",
//       "title": "Western Union Co",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WRK",
//       "title": "WestRock",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WY",
//       "title": "Weyerhaeuser",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WHR",
//       "title": "Whirlpool Corp.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WMB",
//       "title": "Williams Cos.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WLTW",
//       "title": "Willis Towers Watson",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "WYNN",
//       "title": "Wynn Resorts Ltd",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XEL",
//       "title": "Xcel Energy Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XRX",
//       "title": "Xerox",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XLNX",
//       "title": "Xilinx",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "XYL",
//       "title": "Xylem Inc.",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "YUM",
//       "title": "Yum! Brands Inc",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ZBH",
//       "title": "Zimmer Biomet Holdings",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ZION",
//       "title": "Zions Bancorp",
//       "asset": "EQ"
//     },
//     {
//       "ticker": "ZTS",
//       "title": "Zoetis",
//       "asset": "EQ"
//     }
//   ],
//   }, {
//   title: 'FX',
//   children:
//   [
//     {
//       ticker: 'AUDUSD',
//       title: 'AUD/USD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'EURCHF',
//       title: 'EUR/CHF',
//       asset: 'FX',
//     },
//     {
//       ticker: 'EURGBP',
//       title: 'EUR/GBP',
//       asset: 'FX',
//     },
//     {
//       ticker: 'EURJPY',
//       title: 'EUR/JPY',
//       asset: 'FX',
//     },
//     {
//       ticker: 'EURUSD',
//       title: 'EUR/USD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'GBPDKK',
//       title: 'GBP/DKK',
//       asset: 'FX',
//     },
//     {
//       ticker: 'GBPUSD',
//       title: 'GBP/USD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'NZDUSD',
//       title: 'NZD/USD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDBRL',
//       title: 'USD/BRL Spot',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDCAD',
//       title: 'USD/CAD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDCHF',
//       title: 'USD/CHF',
//       asset: 'FX'
//     },
//     {
//       ticker: 'USDCNY',
//       title: 'USD/CNY Spot',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDINR',
//       title: 'USD/INR Spot',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDJPY',
//       title: 'USD/JPY',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDMXN',
//       title: 'USD/MXN',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDRUB',
//       title: 'USD/RUB Spot',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDSGD',
//       title: 'USD/SGD',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDTRY',
//       title: 'USD/TRY',
//       asset: 'FX',
//     },
//     {
//       ticker: 'USDZAR',
//       title: 'USD/ZAR',
//       asset: 'FX'
//     }
//   ],
//   }, {
//   title: 'Cryptocurrencies',
//   children:
//   [
//     {
//       ticker: 'USDBTC',
//       title: 'USD/BTC Bitcoin',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDETH',
//       title: 'USD/ETH Ethereum',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDXRP',
//       title: 'USD/XRP Ripple',
//       asset: 'Crypto'
//     },
//     {
//       ticker: 'USDLTC',
//       title: 'USD/LTC Litecoin',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDBCH',
//       title: 'USD/BCH Bitcoin Cash',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDEOS',
//       title: 'USD/EOS EOS',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDBNB',
//       title: 'USD/BNB Binance Coin',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDTRX',
//       title: 'USD/TRX TRON',
//       asset: 'Crypto',
//     },
//     {
//       ticker: 'USDADA',
//       title: 'USD/ADA Cardano',
//       asset: 'Crypto',
//     }
//   ],
// }];
//
// export const dataSourceDemo = [{
//   title: 'Equities',
//   children:
//   [
//     {
//       ticker: 'SPX',
//       title: 'S&P 500',
//       asset: 'EQ',
//     }
//   ],
//   }, {
//   title: 'FX',
//   children:
//   [
//     {
//       ticker: 'EURUSD',
//       title: 'EUR/USD',
//       asset: 'FX',
//     },
//   ],
//   }, {
//   title: 'Cryptocurrencies',
//   children:
//   [
//     {
//       ticker: 'USDBTC',
//       title: 'USD/BTC Bitcoin',
//       asset: 'Crypto',
//     },
//   ],
// }];
