import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../Constants/routes';
import { withAuthentication } from '../Session'
import { Layout } from 'antd';

import Navigation          from '../Navigation/Navigation';
import Analysis            from '../Analysis/Analysis'
import LandingPage         from '../Landing/Landing';
import SignInPage          from '../SignIn/';
import SignUpPage          from '../SignUp/';
import TermsAndConditions  from '../TermsAndConditions/TermsAndConditions'
import PasswordForgetPage  from '../PasswordForget/'
import Demo                from '../Analysis/AnalysisDemo'
import Patterns            from '../Patterns';
import BackTest            from '../Backtest';
import FeedbackPage        from '../Feedback';

const { Content, Footer } = Layout;

const App = () =>(
  <Router>
    <Layout className="layout">
      <Navigation />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.ANALYSIS} component={Analysis} />
      <Route path={ROUTES.PATTERNS} component={Patterns} />
      <Route path={ROUTES.DEMO} component={Demo} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.TERMSANDCONDITIONS} component={TermsAndConditions} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.BACKTEST} component={BackTest} />
      <Route path={ROUTES.FEEDBACK} component={FeedbackPage} />
      <Footer style={{ textAlign: 'center' }}>Haystak © 2019</Footer>
    </Layout>
  </Router>
)

export default withAuthentication(App);
