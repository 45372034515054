import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import './index.css';
import { Form, Icon, Input, Button, Checkbox, Layout, Row, Col} from 'antd';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants/routes'

const { Content } = Layout;

const SignUpPage = () =>(
  <Layout>
    <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
      <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
        <Row justify="center" type="flex">
          <h2>Create an Account</h2>
        </Row>
        <Row justify="center" type="flex">
          <h1>to continue to Haystak</h1>
        </Row>
        <br></br>
        <Row justify="center" type="flex">
          <SignUpForm />
        </Row>

      </div>
    </Content>
  </Layout>
)

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne:'',
  passwordTwo:'',
  error: null
}

class SignUpFormBase extends React.Component{
  constructor(props){
    super(props)
    this.state = {... INITIAL_STATE}
  }

  onSubmit = event => {
    const {username, email, passwordOne} = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.ANALYSIS)
      })
      .catch(error => {
        this.setState({error});
      })
      event.preventDefault();
  }

  onChange = event =>{
    this.setState({[event.target.name]: event.target.value})
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <Form onSubmit={this.onSubmit} className="login-form">
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Username"
          allowClear={true}
          />
        <br></br>
        <br></br>
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
          allowClear={true}
          />
        <br></br>
        <br></br>
        <Input
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
          allowClear={true}
          />
        <br></br>
        <br></br>
        <Input
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
          allowClear={true}
          />
          &nbsp;&nbsp; <br></br><br></br>
        <Button disable={isInvalid} type="primary" htmlType="submit" className="login-form-button">
          Sign up
        </Button>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const SignUpLink = () => (
  <Link to={ROUTES.SIGN_UP}>register now</Link>
);

const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase)

export default SignUpPage
export {SignUpForm, SignUpLink};
