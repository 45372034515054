import React from 'react'
import { Form, Icon, Input, Button, Layout, Row, Col, Typography} from 'antd';
import axios from 'axios';

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const FeedbackPage = () => (
  <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
    <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
      <Row justify="center" type="flex">
        <Title level={3}>Share your feedback</Title>
      </Row>
      <Row justify="center" type="flex">
        <h1>We use your suggestions to improve Haystak and build new features</h1>
      </Row>
      <br/>
      <Row justify="center" type="flex">
        <Col xs={24} md={12}>
          <FeedbackForm />
        </Col>
      </Row>
    </div>
  </Content>
)

const INITIAL_STATE = {
  feedback:'',
  submitted_successfully: false,
};

class  FeedbackForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {... INITIAL_STATE};
  }

  onSubmit = () => {
    const request = {
      feedback: this.state.feedback
    }
    axios.post('https://haystak-api-heroku.herokuapp.com/feedback/', request)
      .then(res =>{
          this.setState({
            feedback: '',
            submitted_successfully: true
          })
        })
      .catch(err => {
        alert("Oops something went wrong, please refresh the page!")
      })
  }

  onChange = event =>{
    this.setState({
      feedback: event.target.value,
      submitted_successfully: false
     });
  };

  render(){
    const isInvalid = this.state.feedback === '';

    return (
      <div>
        <Row justify="center" type="flex">
          <TextArea rows={4} value={this.state.feedback} onChange={this.onChange}/>
          <br/>
          {this.state.submitted_successfully && <p>Thanks for your feedback!</p>}
        </Row>
        <br/>
        <Row justify="center" type="flex">
          <Button disabled={isInvalid} type="primary" onClick={this.onSubmit} style={{width:"20%"}}>Submit</Button>
        </Row>
      </div>
    )
  }
}

export default FeedbackPage
