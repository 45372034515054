import React from 'react';
import ReactApexChart from 'react-apexcharts'

export default class CandleStickApex extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        title: {
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM yy',
            },
          },
        },
        yaxis: {
          tooltip: {
            enabled: true
          },
          title: {
             text: 'Price'
           },
          labels: {
            formatter: function (value) {
              return Math.round(value);
            }
          }
        },
      },
    }
  }

  parseData = () => {
    let parsedData = []
    const prices = this.props.prices
    const dates = this.props.dates
    for(let i=0; i<prices.length; i++){
      const datapoint = {
        x: new Date(dates[i]),
        y: prices[i].slice(0,4)
      }
      parsedData.push(datapoint)
    }
    return parsedData
  }

  render() {

    const series = [{
      data: this.parseData()
    }]

    return (
      <div>
        <ReactApexChart options={this.state.options} series={series} type="candlestick" height="350" />
      </div>
    );
  }
}
