import React from "react";
import { Icon, Input, AutoComplete } from 'antd';
import { dataSource } from './TickerOverviewDemo'
import './Suggest.css';

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

const options = dataSource.map(group => (
    <OptGroup
      key={group.title}
    >
      {group.children.map(opt => (
        <Option key={opt.asset} value={opt.ticker}>
          {opt.title}
          <span className="display-ticker">{opt.ticker}</span>
        </Option>
      ))}
    </OptGroup>
  ));

export function AutoCompleteBox({ onChange }) {
  return (
      <AutoComplete
        dropdownMatchSelectWidth={true}
        style={{
          margin: '0 8px 8px 0',
          width: '100%'
        }}
        dataSource = {options}
        placeholder = "&nbsp;&nbsp;&nbsp;&nbsp; Find instrument (e.g. Apple)..."
        optionLabelProp="value"
        filterOption={true}
        optionFilterProp={"children"}
        onChange={onChange}
        allowClear={true}
        >
        <Input
          prefix={<Icon type="stock"
          style={{ color: 'rgba(0,0,0,.25)' }}
          />}
        />
      </AutoComplete>
  );
}
