import React from 'react';
import axios from 'axios';
import moment from 'moment';
import MainSecurity from '../Analysis/Charts/mainSecurity'
import { AutoCompleteBox } from '../Analysis/Suggestions/Suggest'
import  PerformanceChart  from '../Analysis/Charts/performanceChart'
import ChartContainer from '../Analysis/Charts/chartContainer';
import CandleStickApex from '../Analysis/Charts/CandleStickApex';
import * as ROUTES from '../Constants/routes';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, DatePicker, Tabs, Select, Radio, Typography, Divider, Popover, Table, Layout, Spin, Icon, Progress } from 'antd';
import { withAuthorization } from '../Session';
import { dataSource } from '../Analysis/Suggestions/TickerOverview'

const { Option } = Select;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Content } = Layout;

function parseNameFromTicker(dataObj, ticker) {
  for(let i=0; i < dataObj.length; i++){
    if(dataObj[i].ticker == ticker) {
      return dataObj[i].title
    }
  }
  return ticker
}

const CurrentPerformanceText = (
  <div>
  <Paragraph>
    <ul>
      <li>View price history for current date range</li>
      <li>This is the base pattern - all the matching patterns are similar to this one</li>
    </ul>
    </Paragraph>
  </div>
)
const MatchingPatternsText = (
  <div>
  <Paragraph>
    <ul>
      <li>This is a list of all individual matching patterns to the base pattern</li>
      <li>Matching patterns are found using the Haystak engine</li>
      <li>The Haystak similarity score is measure of similarity to the base pattern (0 is no match, 100 is perfect match)</li>
    </ul>
    </Paragraph>
  </div>
)

const AggregateText = (
  <div>
  <Paragraph>
    <ul>
      <li>This shows the following returns of all matching patterns</li>
      <li>It also shows the percentage of those matching patterns that had a positive return</li>
    </ul>
  </Paragraph>
  </div>
)

const PotentialScoreText = (
  <div>
  <Paragraph>
    <ul>
      <li>The Haystak engine assigns this score depending on how interesting the pattern is </li>
      <li>100 is the maximum and 0 the minimum </li>
    </ul>
    </Paragraph>
  </div>
)

class Patterns extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data: null,
      loading: false,
      main_stock_data: null,
      matches:null,
      load_ticker:null,
    }
  }

  componentDidMount(){
    axios.get('https://haystak-api-heroku.herokuapp.com/patterns/')
      .then(res =>{
        let results = res.data.results
        for(let i=0; i<results.length; i++){
          results[i]['start'] = moment(results[i]['start']).format('DD-MM-YYYY');
          results[i]['end'] = moment(results[i]['end']).format('DD-MM-YYYY');
          results[i]['score'] = 1 - Math.tanh((1 / results[i]['score']) / 100);
          results[i]['name'] = results[i]['name']
        }
        this.setState({data: results})
      })
    }

  fetchStock = (text) => {
    this.setState({loading: true, matches:null, main_stock_data:null, load_ticker:text.ticker});
    const start_date = moment(text.start, 'DD-MM-YYYY').toDate();
    const end_date = moment(text.end, 'DD-MM-YYYY').toDate();
    const request1 = {
      ticker: text.ticker,
      asset: 'EQ',
      start: start_date,
      end: end_date,
    }

    axios.post('https://haystak-api-heroku.herokuapp.com/main/', request1)
      .then(res => {
        const request2 = {
          ticker: text.ticker,
          range: res.data.range,
          asset: 'EQ',
          main_stock_prices: res.data,
        }
        this.setState({
          main_stock_data: res.data,
        })
      return axios.post(`https://haystak-api-heroku.herokuapp.com/api/`, request2)
      .then(res => {
        this.setState({matches: res.data, loading: false});
      })
      .catch(err => {
        alert("An error occured please refresh the page")
        this.setState({loading: false});
      })
    })
  }

  render(){

    let name_data = dataSource[1].children

    const columns = [
      {
        title: 'Instrument',
        dataIndex: 'ticker',
        key: 'name',
        render: ticker => parseNameFromTicker(name_data, ticker),
      },
      {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        render: text => <a>{text}</a>,
      },
      {
        title: 'Start',
        dataIndex: 'start',
        key: 'start',
      },
      {
        title: 'End',
        dataIndex: 'end',
        key: 'end',
      },
      {
        title: <div>Potential Score <a href="#"><Popover content={PotentialScoreText} title="Potential Score"><Button shape="circle" size="small" icon="info" type="default"></Button></Popover></a></div>,
        dataIndex: 'score',
        key: 'score',
        render: (score) => (
          <div style={{ width: 200 }}>
          <Progress
            strokeColor=
            {{
              from: '#108ee9',
              to: '#87d068',
            }}
            percent={score.toFixed(2)*100}
            format={percent => `${percent}/100`}
            status="active"
          />
          </div>
        )
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button icon="fund" type="primary" onClick={(e) => {this.fetchStock(text)}}>Analyse</Button>
        )}
    ]

    const loading   = this.state.loading
    const matches   = this.state.matches
    const main      = this.state.main_stock_data
    const tix       = this.state.load_ticker

    return(
      <div>
        <Layout className="layout">
          <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
            <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
              <Title level={3}>Today's five most interesting price patterns</Title>
              <Row>
                <Table columns={columns} dataSource={this.state.data} pagination={false}/>
              </Row>
              <Divider/>
              {loading &&
                <Col align="middle">
                  <Icon type="loading" style={{ fontSize: 24 }} spin />
                </Col>
              }
              {matches &&
                <div>
                  <Title level={3}>Analysis of {parseNameFromTicker(name_data, tix)} ({tix})</Title>
                  {/*Main Ticker*/}
                  <Row gutter={40} justify="space-between" type="flex">
                    <Col md={10} xs={24}>
                      <Card
                        title={"Current performance"}
                        type="inner"
                        size="small"
                        extra={<a href="#"><Popover content={CurrentPerformanceText} title="Current performance"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
                        >
                        <CandleStickApex prices={main.chart_full} dates={main.dates} />
                      </Card>
                    </Col>
                    {/*Matches*/}
                    <Col md={14} xs={24}>
                      <Card
                       title={"Matching patterns"}
                       type="inner"
                       size="small"
                         extra={<a href="#"><Popover content={MatchingPatternsText} title="Matching patterns"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
                      >
                        <Row>
                          <ChartContainer prices={matches.stock_prices} dates={matches.dates} scores={matches.score} future_performance={matches.future_performance}/>
                        </Row>
                      </Card>
                     </Col>
                    </Row>
                    {/*Aggregate*/}
                    <br/>
                    <Row gutter={40} justify="center" type="flex">
                      <Col md={16} xs={24}>
                        <Card
                          title={"Aggregate matching patterns performance"}
                          type="inner"
                          size="small"
                          extra={<a href="#"><Popover content={AggregateText} title="Aggregate matching patterns performance"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
                        >
                          <PerformanceChart returns={matches.future_performance} average={matches.future_performance_avg} haystakScore={matches.main_stock.statistics.score_overall} positive_split={matches.positive_split}/>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                }
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Patterns);
