export const dataSource = [{
  title: 'Indices',
  children:
  [
    {
      ticker: 'SPX',
      title: 'S&P 500',
      asset: 'EQ',
    }
  ],
  }, {
  title: 'Equities',
  children:
  [
    {
      "ticker": "MSFT",
      "title": "Microsoft Corp.",
      "asset": "EQ"
    },
    {
      "ticker": "GOOGL",
      "title": "Alphabet Inc Class A",
      "asset": "EQ"
    },
    {
      "ticker": "AMZN",
      "title": "Amazon.com Inc.",
      "asset": "EQ"
    },
    {
      "ticker": "AAPL",
      "title": "Apple Inc.",
      "asset": "EQ"
    }
  ],
  }, {
  title: 'FX',
  children:
  [
    {
      ticker: 'EURUSD',
      title: 'EUR/USD',
      asset: 'FX',
    }
  ],
  }, {
  title: 'Cryptocurrencies',
  children:
  [
    {
      ticker: 'USDBTC',
      title: 'USD/BTC Bitcoin',
      asset: 'Crypto',
    }
  ],
}];
