import React from 'react';
import CandleStickSimpleChart from './CandleStickSimple';
import CandleStickChartWithCHMousePointer from './CandleStickChartWithCHMousePointer';
import { Statistic, Col } from 'antd';

export default class StockChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      main: false,
    };
  }

  parseData(stockPerformance, dates){
      if (stockPerformance){
          let stockData = [];
          for (let i=0; i<stockPerformance.length; i+=1) {
            let date_string = dates[i] + 'T05:00:00.000Z'
            let date = new Date(date_string)
                  let stock_obj = this.parseHelper(date, stockPerformance[i][0], stockPerformance[i][1], stockPerformance[i][2], stockPerformance[i][3], stockPerformance[i][4])
                  stockData.push(stock_obj)
          }
          return  stockData;
      }
      return null;
  }

  parseHelper(date, open, high, low, close, volume) {
    var stock = {};
    stock.date = date;
    stock.open = +open;
    stock.high = +high;
    stock.low = +low;
    stock.close = +close;
    stock.volume = +volume;
    return stock
  }

  render() {
    const data = this.parseData(this.props.chartdata, this.props.dates)
    const perform = this.props.performance;
    const score = 1 - Math.tanh(this.props.score).toFixed(2);

    return (
      <div>
        {(perform && score) &&
        <div>
          <Col>
            <CandleStickChartWithCHMousePointer type={'svg'} data={data} num_datapoints={data.length} />
          </Col>
                  </div>
        }
      </div>
    );
  }
}

//
// <div>
//   <Col span={6}>
//     <div>
//         <Statistic
//         title={this.props.horizons[0] + '-day'}
//         value={perform[0]*100}
//         precision={2}
//         suffix="%"
//         />
//     </div>
//   </Col>
//   <Col span={6}>
//     <div>
//         <Statistic
//         title={this.props.horizons[1] + '-day'}
//         value={perform[1]*100}
//         precision={2}
//         suffix="%"
//         />
//     </div>
//   </Col>
//   <Col span={6}>
//     <div>
//         <Statistic
//         title={this.props.horizons[2] + '-day'}
//         value={perform[2]*100}
//         precision={2}
//         suffix="%"
//         />
//     </div>
//   </Col>
//   <Col span={6}>
//     <div>
//         <Statistic
//         title={this.props.horizons[3] + '-day'}
//         value={perform[3]*100}
//         precision={2}
//         suffix="%"
//         />
//     </div>
//   </Col>
// </div>
