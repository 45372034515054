import React from 'react';
import { Pagination } from 'antd';
import StockChart from './StockChart';
import HaystakScore from './haystakScore';
import { Row, Col, Card, Divider, Button, Select, Statistic, Radio, Slider} from 'antd';
import CandleStickApex from './CandleStickApex';
import LineChart from 'react-linechart';
import 'antd/dist/antd.css';
const { Option } = Select;

export default class ChartContainer extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      matchSelected: 0,
      mode: '0'
    }

    this.changeMatch = this.changeMatch.bind(this);
  }

  changeMatch(e){
    this.setState({
      matchSelected: e - 1
    })
  }

  render(){
    const selected = this.state.matchSelected
    const prices = this.props.prices[selected]
    const dates = this.props.dates[selected]
    const future_performance = this.props.future_performance[selected]
    const score = Math.pow(1 - Math.tanh(this.props.scores[selected]),2);
    const isMobile = window.innerWidth <= 500;

    return(
      <div>
        <Row gutter={20}>
          <Col xs={24} md={17}>
            <CandleStickApex prices={prices} dates={dates} />
          </Col>
          <Col xs={24} md={7} align="middle">
            <Row>
              <p>Following returns</p>
              <Statistic
                    title={'7-days'}
                    value={future_performance[6]*100}
                    precision={2}
                    suffix="%"
                  />
            </Row>
            <Row>
              <Statistic
                    title={ '14-days'}
                    value={future_performance[13]*100}
                    precision={2}
                    suffix="%"
                  />
            </Row>
            <Row>
              <Statistic
                    title={'28-days'}
                    value={future_performance[28]*100}
                    precision={2}
                    suffix="%"
                  />
            </Row>
            <br/>
            <Row>
              <HaystakScore score={score} />
            </Row>
          </Col>
        </Row>
        <br/>
        <Row type="flex" justify="center" gutter={20}>
              <Col><p>Most similiar</p></Col>
              <Col>
                <Pagination
                  defaultCurrent={1}
                  total={this.props.prices.length}
                  pageSize={1}
                  onChange={this.changeMatch}
                />
              </Col>
              <Col><p>Least similiar</p></Col>
        </Row>
      </div>
    )
  }
}
