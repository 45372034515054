import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../Constants/routes';
import { Layout, Menu } from 'antd';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';
const { Header  } = Layout;

const Navigation = ({ authUser }) =>(
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth />: <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
)

const NavigationAuth = () =>(
  <Header>
    <Menu
      theme="dark"
      mode="horizontal"
      style={{ lineHeight: '64px' }}
    >
      <Menu.Item key="1">
        <Link to={ROUTES.LANDING}>
          <img alt="logo" src="haystak_logo_white.png" height="25px"/>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={ROUTES.ANALYSIS}>Analysis</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={ROUTES.BACKTEST}>Backtest</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={ROUTES.PATTERNS}>Daily patterns</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to={ROUTES.FEEDBACK}>Feedback</Link>
      </Menu.Item>
      <Menu.Item key="6" style={{float: 'right'}}>
          <SignOutButton />
      </Menu.Item>
    </Menu>
  </Header>
);

const NavigationNonAuth = () => (
  <Header>
    <Menu
      theme="dark"
      mode="horizontal"
      style={{ lineHeight: '64px' }}
    >
      <Menu.Item key="1">
        <Link to={ROUTES.LANDING}>
          <img alt="logo" src="haystak_logo_white.png" height="25px"/>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={ROUTES.DEMO}>Demo</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={ROUTES.BACKTEST}>Backtest</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={ROUTES.SIGN_IN}>Sign in</Link>
      </Menu.Item>
    </Menu>
  </Header>
)

export default Navigation;
