import React from 'react';
import './css/color.css'
import './css/main.css'
import { AuthUserContext } from '../Session';

const LandingPage = ({ authUser }) =>(
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <LandingAuth />: <LandingNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
)

const LandingAuth = () =>(
  <div>
    <div class="lightblue-white">
      <section class="section">
        <div class="container mb10">
          <div class="col-12 text-center">
            <h1 class="heading primary-color">AI powered technical analysis</h1>
            <h2 class="subheading secondary-color mt20">Haystak's pattern-matching AI algorithm compares years of market data to today's price movements</h2>
            <div class="mt40"><a href="/analysis" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>Try it now</span></a></div>
            <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
          </div>
        </div>
        <div class="container-lrg mt75">
          <div class="col-12">
            <div class=""><img src="07e36055-46b4-477a-bc97-303ba759bf95.png" alt="Screenshot of application" class="custom-img"/></div>
          </div>
        </div>
      </section>
    </div>
    <section id="featuredin-1" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center mb50">
        <div class="col-12">
          <p class="subheading secondary-color mt20">Built by a team from</p>
        </div>
      </div>
      <div class="container-lrg">
        <div class="flex center-horizontal wrap center-vertical">
          <div class="col-3 mobile-col-6 mt20"><a href="" target="_blank"><img src="9043043d-d016-465a-a5a2-e6d06f933079.png" class="press-logo"/></a></div>
          <div class="col-3 mobile-col-6 mt20"><a href="" target="_blank"><img src="a108c588-e039-451f-80b1-48723a208bb3.png" class="press-logo"/></a></div>
        </div>
      </div>
    </section>
    <section id="feature-2" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container">
        <div class="col-12 text-center flex flex-column center-horizontal">
          <h3 class="heading primary-color">How it works</h3>
          <p class="subheading secondary-color mt20">We help you recognise promising patterns in financial instruments by comparing what is happening now to 20+ years of trading data.</p>
        </div>
      </div>
      <div class="container-lrg mt75">
        <div class="col-12">
          <div class=""><img src="5cc83d15-aa33-4d20-add6-eedcc35ff576.png" alt="Screenshot of application" class="custom-img"/></div>
        </div>
      </div>
    </section>
    <section id="cta-3" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <h4 class="heading-lrg primary-color">The Haystak Engine has been backtested extensively</h4>
          <div class="mt40"><a href="/backtest" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>View backtest</span></a></div>
          <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
        </div>
      </div>
    </section>
    <section id="feature-4" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="flex col-6 flex-column mobile-text-center center-vertical"><i class="icon mobile-center-icon secondary-bg"><svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M8.543 16.858l7.635-4.192.202 3.028-8.068 4.632zM8 10V9a1 1 0 0 1 1-1V4.5a3.49 3.49 0 0 1 3-3.45V.5a.5.5 0 0 1 1 0v.55a3.49 3.49 0 0 1 3 3.45V8a1 1 0 0 1 1 1v1a.993.993 0 0 1-.934.987l.038.578-7.482 4.109.312-4.687A.993.993 0 0 1 8 10zm5-2h2V6h-2zm-3 0h2V6h-2zm-1 2h7V9H9zm8.5-5a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 17.5 5zm-.158 2.975l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zm-16-5l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zM1.5 10a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 1.5 10zM25 24.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1 0-1h7.567l.165-2.475 8.222-4.72.48 7.195H24.5a.5.5 0 0 1 .5.5zm-11-2a1.5 1.5 0 0 0-3 0V24h3z"></path>
            </svg></i>
          <h3 class="bold primary-color">Generate trade ideas</h3>
          <p class="paragraph secondary-color">Use the power of statistics and machine learning to do the boring work of going through years of financial data to find investment opportunities.</p>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="15e4daff-3093-4b42-aa93-a0dbf8c02ff7.svg" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="feature-5" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="flex col-6 flex-column mobile-text-center center-vertical"><i class="icon mobile-center-icon secondary-bg"><svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M19.974 23.342A.5.5 0 0 1 19.5 24h-15a.5.5 0 0 1-.474-.658l.552-1.658a1 1 0 0 1 .95-.684h12.945a1 1 0 0 1 .949.684zM21 8.66v.317a.498.498 0 0 1-.108.31l-1.296 1.635a2.503 2.503 0 0 1-2.354.916 18.129 18.129 0 0 1-2.224-.497v.002a5.661 5.661 0 0 1-2.227-1.166.5.5 0 0 0-.707.707 7.17 7.17 0 0 0 3.058 1.541 7.984 7.984 0 0 0 2.389 4.386A4.063 4.063 0 0 1 19 19.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5c0-.341.238-.643.633-1.142A4.96 4.96 0 0 0 7 15.5a5.84 5.84 0 0 0-.811-2.607A9.226 9.226 0 0 1 5 8.5C5 4.435 8.893 1 13.5 1a.5.5 0 0 1 .5.5v1.71l5.74 3.28A2.504 2.504 0 0 1 21 8.66zM15 6.5a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5z"></path>
            </svg></i>
          <h3 class="bold primary-color">Validate trade ideas</h3>
          <p class="paragraph secondary-color">Thinking about buying a stock? Use the Haystak Engine to see what happened last time the stock behaved like it's doing now.</p>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="77ca54f8-02c3-4039-a2e8-b1764114d0fa.svg" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="checklist-6" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="col-6 flex flex-column center-vertical">
          <h3 class="heading primary-color">Analyze all asset classes</h3>
          <ul class="checklist mt20">
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">Equity</p>

            </li>
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">Crypto</p>
            </li>
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">FX</p>
            </li>
          </ul>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="d3e11f8c-57a7-4785-bba1-56026d317b1f.png" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="cta-7" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <h4 class="heading-lrg primary-color">Stop guessing. Validate your trading hunches</h4>
          <div class="mt40"><a href="/analysis" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>Try it now</span></a></div>
          <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
        </div>
      </div>
    </section>
    <section id="faq-8" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <p class="subheading secondary-color mt20">Legal</p>
        </div>
      </div>
      <div class="container mt40">
        <div class="col-12">
          <div class="faq pad20"><input id="faq-dropdown-8-0" type="checkbox" name="faq" class="faq-input"/> <label for="faq-dropdown-8-0" class="faq-label flex spread center-horizontal"><span class="bold primary-color mr20">Disclaimer</span> <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" class="noshrink svg-fill">

              </svg></label>
            <p class="paragraph secondary-color faq-content mt20">
              Any and all information listed on this website www.haystak.ai is for educational and informational purposes only. We, Haystak, do not guarantee any information on this website to be accurate. Investing in the stock market and trading equities, bonds, currencies, crypto currencies and commodities, involves substantial risks and is not suitable for all investors/traders. Before investing, be sure to consult a registered investment advisor or licensed stock broker. Any information and analysis presented on this website are purely for entertainment purposes and should not be considered investment advice. We, Haystak, are not a licensed broker or an investment adviser. As such, we are not qualified to provide any type of investment advice. A referral to a stock on our website is not an indication to buy or sell that stock. Past performance may not be indicative of future results. Joining this analysis service does not guarantee that you will become a profitable trader.
              U.S. Government Required Disclaimer: The potential reward of trading stocks is large, but there is also a large risk in trading. In order to trade the stock market or invest in it, you must be willing to accept these risks. This website does not solicit nor offer to buy and sell stocks. Do not invest or trade with money that you cannot afford to lose. Past performance may not be indicative of future results and so no representation is being made that any account will achieve the same or similar profits/losses discussed on the website.
              US Securities and Exchange Commission (SEC) requires the following legal notices: All material presented within www.haystak.ai is not to be regarded as investment advice, but for general informational purposes only. Trading of any sorts involves substantial risk and caution must be utitlized. We, Haystak, cannot guarantee profits or freedom from loss. We do not make any investment or trading decisions for anyone. Thus, you assume the entire cost and risk of any trading you choose to undertake. You are solely responsible for making your own investment decisions. The website www.haystak.ai its members and representatives are not registered as securities broker-dealers or investment advisors either with the U.S. Securities and Exchange Commission or with any state securities regulatory authority. We, Haystak, are not qualified to provide any type of investment advice and so you should consult such advice from a registered investment advisor, broker-dealer, and/or financial advisor. You accept that any investments or trades made without the advice from such an adviser or entity are your sole responsibility.

            </p>
          </div>
          <div class="faq pad20"><input id="faq-dropdown-8-1" type="checkbox" name="faq" class="faq-input"/> <label for="faq-dropdown-8-1" class="faq-label flex spread center-horizontal"><span class="bold primary-color mr20">Release of Liability</span> <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" class="noshrink svg-fill">
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z"></path>
              </svg></label>
            <p class="paragraph secondary-color faq-content mt20">
              By entering or using this website, you are indicating your consent and agreement to our disclaimer &amp; release of liability. You agree www.haystak.ai will not be held responsible for any losses (monetary or otherwise), damage (monetary or otherwise), and injury (monetary or otherwise) that you or any third party may incur as a result of using our website or any of our services including. You agree to completely release us from any and all liability for any type of losses (monetary or otherwise) or damages (monetary or otherwise) that you or a third party may incur from your use of our services, website, or in trading any form of financial securities.
              All Haystak services are for informational and entertainment purposes only. These products should not be taken to be accurate or to be used as investment/trading advice. Any statement, opinion, trade idea, or information presented is not guaranteed or warranted by us to be accurate. Haystak is not responsible for any errors or omissions. Any and all opinions and data expressed are subject to change without notice. You understand and agree that any content, service, or information included or obtained on this website may not be accurate and is presented "as is". We, Haystak, hold no warranties of any kind on information we share or post on this website
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
)

const LandingNonAuth = () =>(
  <div>
    <div class="lightblue-white">
      <section class="section">
        <div class="container mb10">
          <div class="col-12 text-center">
            <h1 class="heading primary-color">AI powered technical analysis</h1>
            <h2 class="subheading secondary-color mt20">Haystak's pattern-matching AI algorithm compares years of market data to today's price movements</h2>
            <div class="mt40"><a href="/demo" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>Try Free Demo</span></a></div>
            <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
          </div>
        </div>
        <div class="container-lrg mt75">
          <div class="col-12">
            <div class=""><img src="07e36055-46b4-477a-bc97-303ba759bf95.png" alt="Screenshot of application" class="custom-img"/></div>
          </div>
        </div>
      </section>
    </div>
    <section id="featuredin-1" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center mb50">
        <div class="col-12">
          <p class="subheading secondary-color mt20">Built by a team from</p>
        </div>
      </div>
      <div class="container-lrg">
        <div class="flex center-horizontal wrap center-vertical">
          <div class="col-3 mobile-col-6 mt20"><a href="" target="_blank"><img src="9043043d-d016-465a-a5a2-e6d06f933079.png" class="press-logo"/></a></div>
          <div class="col-3 mobile-col-6 mt20"><a href="" target="_blank"><img src="a108c588-e039-451f-80b1-48723a208bb3.png" class="press-logo"/></a></div>
        </div>
      </div>
    </section>
    <section id="feature-2" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container">
        <div class="col-12 text-center flex flex-column center-horizontal">
          <h3 class="heading primary-color">How it works</h3>
          <p class="subheading secondary-color mt20">We help you recognise promising patterns in financial instruments by comparing what is happening now to 20+ years of trading data.</p>
        </div>
      </div>
      <div class="container-lrg mt75">
        <div class="col-12">
          <div class=""><img src="5cc83d15-aa33-4d20-add6-eedcc35ff576.png" alt="Screenshot of application" class="custom-img"/></div>
        </div>
      </div>
    </section>
    <section id="cta-3" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <h4 class="heading-lrg primary-color">The Haystak Engine has been backtested extensively</h4>
          <div class="mt40"><a href="/backtest" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>See the backtest</span></a></div>
          <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
        </div>
      </div>
    </section>
    <section id="feature-4" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="flex col-6 flex-column mobile-text-center center-vertical"><i class="icon mobile-center-icon secondary-bg"><svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M8.543 16.858l7.635-4.192.202 3.028-8.068 4.632zM8 10V9a1 1 0 0 1 1-1V4.5a3.49 3.49 0 0 1 3-3.45V.5a.5.5 0 0 1 1 0v.55a3.49 3.49 0 0 1 3 3.45V8a1 1 0 0 1 1 1v1a.993.993 0 0 1-.934.987l.038.578-7.482 4.109.312-4.687A.993.993 0 0 1 8 10zm5-2h2V6h-2zm-3 0h2V6h-2zm-1 2h7V9H9zm8.5-5a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 17.5 5zm-.158 2.975l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zm-16-5l6 2a.5.5 0 0 0 .316-.95l-6-2a.5.5 0 0 0-.316.95zM1.5 10a.498.498 0 0 0 .158-.025l6-2a.5.5 0 0 0-.316-.95l-6 2A.5.5 0 0 0 1.5 10zM25 24.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1 0-1h7.567l.165-2.475 8.222-4.72.48 7.195H24.5a.5.5 0 0 1 .5.5zm-11-2a1.5 1.5 0 0 0-3 0V24h3z"></path>
            </svg></i>
          <h3 class="bold primary-color">Generate trade ideas</h3>
          <p class="paragraph secondary-color">Use the power of statistics and machine learning to do the boring work of going through years of financial data to find investment opportunities.</p>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="15e4daff-3093-4b42-aa93-a0dbf8c02ff7.svg" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="feature-5" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="flex col-6 flex-column mobile-text-center center-vertical"><i class="icon mobile-center-icon secondary-bg"><svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M19.974 23.342A.5.5 0 0 1 19.5 24h-15a.5.5 0 0 1-.474-.658l.552-1.658a1 1 0 0 1 .95-.684h12.945a1 1 0 0 1 .949.684zM21 8.66v.317a.498.498 0 0 1-.108.31l-1.296 1.635a2.503 2.503 0 0 1-2.354.916 18.129 18.129 0 0 1-2.224-.497v.002a5.661 5.661 0 0 1-2.227-1.166.5.5 0 0 0-.707.707 7.17 7.17 0 0 0 3.058 1.541 7.984 7.984 0 0 0 2.389 4.386A4.063 4.063 0 0 1 19 19.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5c0-.341.238-.643.633-1.142A4.96 4.96 0 0 0 7 15.5a5.84 5.84 0 0 0-.811-2.607A9.226 9.226 0 0 1 5 8.5C5 4.435 8.893 1 13.5 1a.5.5 0 0 1 .5.5v1.71l5.74 3.28A2.504 2.504 0 0 1 21 8.66zM15 6.5a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5z"></path>
            </svg></i>
          <h3 class="bold primary-color">Validate trading ideas</h3>
          <p class="paragraph secondary-color">Thinking about buying a stock? Use the Haystak Engine to see what happened last time the stock behaved like it's doing now.</p>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="77ca54f8-02c3-4039-a2e8-b1764114d0fa.svg" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="checklist-6" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container-lrg flex">
        <div class="col-6 flex flex-column center-vertical">
          <h3 class="heading primary-color">Analyze all asset classes</h3>
          <ul class="checklist mt20">
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">Equity</p>
            </li>
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">Crypto</p>
            </li>
            <li class="checklist-item flex center-horizontal spread pad10 pr0 pl0">
              <p class="span primary-color mr20">FX</p>
            </li>
          </ul>
        </div>
        <div class="col-6 flex center-horizontal center-vertical">
          <div class="launchaco-builder-hoverable">
            <div class=""><img src="d3e11f8c-57a7-4785-bba1-56026d317b1f.png" alt="Screenshot of small App" class="custom-img"/></div>
          </div>
        </div>
      </div>
    </section>
    <section id="cta-7" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <h4 class="heading-lrg primary-color">Stop guessing. Validate your trading hunches</h4>
          <div class="mt40"><a href="/demo" target="_blank" class="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"> <span>Try Free Demo</span></a></div>
          <p style={{margin: '10px 0 0 0'}}>No sign up required</p>
        </div>
      </div>
    </section>
    <section id="faq-8" class="section lightblue-white" sitemeta="[object Object]" activepage="Landing">
      <div class="container text-center">
        <div class="col-12">
          <p class="subheading secondary-color mt20">Legal</p>
        </div>
      </div>
      <div class="container mt40">
        <div class="col-12">
          <div class="faq pad20"><input id="faq-dropdown-8-0" type="checkbox" name="faq" class="faq-input"/> <label for="faq-dropdown-8-0" class="faq-label flex spread center-horizontal"><span class="bold primary-color mr20">Disclaimer</span> <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" class="noshrink svg-fill">

              </svg></label>
            <p class="paragraph secondary-color faq-content mt20">
              Any and all information listed on this website www.haystak.ai is for educational and informational purposes only. We, Haystak, do not guarantee any information on this website to be accurate. Investing in the stock market and trading equities, bonds, currencies, crypto currencies and commodities, involves substantial risks and is not suitable for all investors/traders. Before investing, be sure to consult a registered investment advisor or licensed stock broker. Any information and analysis presented on this website are purely for entertainment purposes and should not be considered investment advice. We, Haystak, are not a licensed broker or an investment adviser. As such, we are not qualified to provide any type of investment advice. A referral to a stock on our website is not an indication to buy or sell that stock. Past performance may not be indicative of future results. Joining this analysis service does not guarantee that you will become a profitable trader.
              U.S. Government Required Disclaimer: The potential reward of trading stocks is large, but there is also a large risk in trading. In order to trade the stock market or invest in it, you must be willing to accept these risks. This website does not solicit nor offer to buy and sell stocks. Do not invest or trade with money that you cannot afford to lose. Past performance may not be indicative of future results and so no representation is being made that any account will achieve the same or similar profits/losses discussed on the website.
              US Securities and Exchange Commission (SEC) requires the following legal notices: All material presented within www.haystak.ai is not to be regarded as investment advice, but for general informational purposes only. Trading of any sorts involves substantial risk and caution must be utitlized. We, Haystak, cannot guarantee profits or freedom from loss. We do not make any investment or trading decisions for anyone. Thus, you assume the entire cost and risk of any trading you choose to undertake. You are solely responsible for making your own investment decisions. The website www.haystak.ai its members and representatives are not registered as securities broker-dealers or investment advisors either with the U.S. Securities and Exchange Commission or with any state securities regulatory authority. We, Haystak, are not qualified to provide any type of investment advice and so you should consult such advice from a registered investment advisor, broker-dealer, and/or financial advisor. You accept that any investments or trades made without the advice from such an adviser or entity are your sole responsibility.

            </p>
          </div>
          <div class="faq pad20"><input id="faq-dropdown-8-1" type="checkbox" name="faq" class="faq-input"/> <label for="faq-dropdown-8-1" class="faq-label flex spread center-horizontal"><span class="bold primary-color mr20">Release of Liability</span> <svg width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" class="noshrink svg-fill">
                <path d="M7.4176407,5.65342711 L1.76421359,8.8817842e-16 L0.350000024,1.41421356 L6.00342714,7.06764068 L6,7.07106781 L7.41421356,8.48528137 L7.4176407,8.48185424 L7.42106784,8.48528137 L8.8352814,7.07106781 L8.83185426,7.06764068 L14.4852814,1.41421356 L13.0710678,8.8817842e-16 L7.4176407,5.65342711 Z"></path>
              </svg></label>
            <p class="paragraph secondary-color faq-content mt20">
              By entering or using this website, you are indicating your consent and agreement to our disclaimer &amp; release of liability. You agree www.haystak.ai will not be held responsible for any losses (monetary or otherwise), damage (monetary or otherwise), and injury (monetary or otherwise) that you or any third party may incur as a result of using our website or any of our services including. You agree to completely release us from any and all liability for any type of losses (monetary or otherwise) or damages (monetary or otherwise) that you or a third party may incur from your use of our services, website, or in trading any form of financial securities.
              All Haystak services are for informational and entertainment purposes only. These products should not be taken to be accurate or to be used as investment/trading advice. Any statement, opinion, trade idea, or information presented is not guaranteed or warranted by us to be accurate. Haystak is not responsible for any errors or omissions. Any and all opinions and data expressed are subject to change without notice. You understand and agree that any content, service, or information included or obtained on this website may not be accurate and is presented "as is". We, Haystak, hold no warranties of any kind on information we share or post on this website
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
)


export default LandingPage;
