export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ANALYSIS = '/analysis';
export const PATTERNS = '/patterns';
export const TEAM = '/team';
export const TERMSANDCONDITIONS = '/termsandconditions';
export const PASSWORD_FORGET = '/passwordforget';
export const DEMO = '/demo';
export const BACKTEST = '/backtest';
export const FEEDBACK = '/feedback';
