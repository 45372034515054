import React from 'react';
import {Tabs, Icon, Typography, Layout, Divider } from 'antd';
import TabPage from './TabContents/TabDemo';
const { Content } = Layout;

const { Title, Text, Paragraph } = Typography;

class Demo extends React.Component {

  render() {
    const isMobile = window.innerWidth <= 500;
    return (
      <div>
        {isMobile &&
          <div>
            <Layout className="layout">
              <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
                <Title level={3}>Demo</Title>
                <Text>This demo is limited. <a href="/signin">Sign in</a> to get <b>free access</b> to the following features:</Text>
                <br/>
                <Paragraph>
                  <ul>
                    <li>Analyse 200+ equities / 6 FX pairs / 2 Crypto pairs</li>
                    <li>Automatically discover new high potential patterns every day</li>
                  </ul>
                </Paragraph>
                <Divider/>
                <TabPage />
              </Content>
            </Layout>
          </div>
        }
        {!isMobile &&
          <Layout className="layout">
            <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
              <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
                <Title level={3}>Demo</Title>
                <Text>This demo is limited. <a href="/signin">Sign in</a> to get <b>free access</b> to the following features:</Text>
                <br/>
                <Paragraph>
                  <ul>
                    <li>Analyse 200+ equities / 6 FX pairs / 2 crypto pairs</li>
                    <li>Automatically discover new high potential patterns every day</li>
                  </ul>
                </Paragraph>
                <Divider/>
                <TabPage />
              </div>
            </Content>
          </Layout>
        }
      </div>
    )
  }
}

export default Demo;
