import React from 'react';
import axios from 'axios';
import moment from 'moment';
import MainSecurity from '../Charts/mainSecurity'
import { AutoCompleteBox } from '../Suggestions/SuggestDemo'
import  PerformanceChart  from '../Charts/performanceChart'
import ChartContainer from '../Charts/chartContainer';
import CandleStickApex from '../Charts/CandleStickApex';
import * as ROUTES from '../../Constants/routes';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, DatePicker, Tabs, Select, Radio, Typography, Divider, Popover } from 'antd';
import { withAuthorization } from '../../Session';

const { Option } = Select;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

const CurrentPerformanceText = (
  <div>
  <Paragraph>
    <ul>
      <li>View price history for current date range</li>
      <li>This is the base pattern - all the matching patterns are similar to this one</li>
    </ul>
    </Paragraph>
  </div>
)
const MatchingPatternsText = (
  <div>
  <Paragraph>
    <ul>
      <li>This is a list of all individual matching patterns to the base pattern</li>
      <li>Matching patterns are found using the Haystak engine</li>
      <li>The Haystak similarity score is measure of similarity to the base pattern (0 is no match, 100 is perfect match)</li>
    </ul>
    </Paragraph>
  </div>
)

const AggregateText = (
  <div>
  <Paragraph>
    <ul>
      <li>This shows the following returns of all matching patterns</li>
      <li>It also shows the percentage of those matching patterns that had a positive return</li>
    </ul>
  </Paragraph>
  </div>
)

class TabPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      main_stock_data: null,
      data: null,
      loading: false,
      stock: null,
      asset: null,
      startDate: moment().subtract(28, 'days'),
      endDate: moment(),
      pickerMode: '0',
      fetched_stock: "",
    }
  }

  componentDidMount(){
    const urlParams = new URLSearchParams(window.location.search);
    const ticker = urlParams.get('ticker');
    const start = urlParams.get('start');

    if(ticker !== null && start !== null){
      this.onDateDropDownChange(start)
      let state = {...this.state, startDate:start, stock: ticker, asses:'EQ'};
      console.log(state)
      this.setState({...state})
    }
  }

  onStartDateChange = (event, date) =>{
    this.setState({startDate: date})
  }

  onDateDropDownChange = (date) => {
    console.log(date)
    this.setState({startDate: date})
  }

  onInputChange = (value, key) => {
    if(key) {
      this.setState({ asset: key.key})
      this.setState({ stock: value });
    }
  };

  fetchStock = () =>  {
    this.setState({main_stock_data: null, loading: true, data:null});
    const request1 = {
      ticker: this.state.stock,
      asset: this.state.asset,
      start: this.state.startDate,
      end: this.state.endDate
    }

    if (request1.ticker != null){
      axios.post('https://haystak-api-heroku.herokuapp.com/main/', request1)
        .then(res =>{
          const request2 = {
            ticker: this.state.stock,
            range: res.data.range,
            asset: this.state.asset,
            main_stock_prices: res.data,
          }
          this.setState({
            main_stock_data: res.data,
            fetched_stock: this.state.stock
          })
          return axios.post(`https://haystak-api-heroku.herokuapp.com/api/`, request2)
        .then(res => {
          console.log(res.data)
          this.setState({data: res.data, loading: false});
        })
        .catch(err => {
          console.log(err)
          this.setState({loading: false});
        })
      })
    }
  }

  switchPickerMode = (e) => {
    this.setState({pickerMode: e.target.value})
  }

  render() {
    const {data, loading} = this.state;
    const pickerMode = this.state.pickerMode
    const isMobile = window.innerWidth <= 500;

    let m_stock = this.state.main_stock_data;
    let main_stock = null;

    if (data){
      main_stock = data.main_stock;
    }

    return (
      <div>
        <Row >
            <Row type="flex" justify="start" gutter={16}>
              <Col md={7} xs={24}>
                <AutoCompleteBox onChange={this.onInputChange} />
              </Col>
              <Col md={4} xs={24}>
                {pickerMode === '0' &&
                <Select
                  placeholder="Pick time horizon..."
                  style={{ width: 167 }}
                  onChange={this.onDateDropDownChange}>
                    <Option value={moment().subtract(14, 'days')}>Last 2 weeks</Option>
                    <Option value={moment().subtract(21, 'days')}>Last 3 weeks</Option>
                    <Option value={moment().subtract(28, 'days')}>Last 4 weeks</Option>
                    <Option value={moment().subtract(42, 'days')}>Last 6 weeks</Option>
                    <Option value={moment().subtract(56, 'days')}>Last 8 weeks</Option>
                </Select>
                }
                {pickerMode === '1' &&
                  <p>
                    <DatePicker placeholder="Start date..." onChange={this.onStartDateChange}/>
                  </p>
                }
              </Col>
              <Col md={5} xs={24}>
              {isMobile &&
                <br/>
              }
              <Button
                      icon="fund"
                      type="primary"
                      onClick={this.fetchStock}
                      loading={loading}
                  >
                      Analyze
                  </Button>
              </Col>

              {!isMobile &&
                <Col md={8} xs={24}>
                  <Radio.Group style={{float:'right'}} value={pickerMode} onChange={this.switchPickerMode}>
                    <Radio.Button value='0'>Select range</Radio.Button>
                    <Radio.Button value='1'>Select date</Radio.Button>
                  </Radio.Group>
                </Col>
              }
            </Row>
        </Row>
        <br/>
        {main_stock &&
          <div>

            {/*Main Stock*/}
            <Row gutter={40} justify="space-between" type="flex">
            <Col md={10} xs={24}>
            {m_stock &&
              <div>
              <Card
                title={"Current performance"}
                type="inner"
                size="small"
                extra={<a href="#"><Popover content={CurrentPerformanceText} title="Current performance"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
                >
                <CandleStickApex prices={m_stock.chart_full} dates={m_stock.dates} />
            </Card>
            </div>
          }
            </Col>
            <Col md={14} xs={24}>
            {main_stock &&
              <div>
               <Card
                title={"Matching patterns"}
                type="inner"
                size="small"
                  extra={<a href="#"><Popover content={MatchingPatternsText} title="Matching patterns"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
               >
               <Row>
                 <ChartContainer prices={data.stock_prices} dates={data.dates} scores={data.score} future_performance={data.future_performance}/>
               </Row>
               </Card>
             </div>
            }
            </Col>
            </Row>

            <br/>

            <Row gutter={40} justify="center" type="flex">
            {/*Most similiar stocks*/}
              <Col md={16} xs={24}>
              {main_stock &&
                  <div>
                   <Card
                    title={"Aggregate matching patterns performance"}
                    type="inner"
                    size="small"
                      extra={<a href="#"><Popover content={AggregateText} title="Aggregate matching patterns performance"><Button shape="circle" icon="info" type="default"></Button></Popover></a>}
                    >
                    <PerformanceChart returns={this.state.data.future_performance} average={this.state.data.future_performance_avg} haystakScore={this.state.data.main_stock.statistics.score_overall} positive_split={this.state.data.positive_split}/>
                   </Card>
                  </div>
                }
              </Col>
            </Row>
         </div>
        }
    </div>
    )
  }
}

export default TabPage;
