import React from 'react';
import { Button } from 'antd'
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Button ghost type="button" onClick={firebase.doSignOut}>
    Sign out
  </Button>
);

export default withFirebase(SignOutButton);
