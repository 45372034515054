const parseData = function(stockPerformance, dates){
    if (stockPerformance){
        let stockData = [];
        for (let i=0; i<stockPerformance.length; i+=1) {
          let date_string = dates[i] + 'T05:00:00.000Z'
          let date = new Date(date_string)
                let stock_obj = parseHelper(date, stockPerformance[i][0], stockPerformance[i][1], stockPerformance[i][2], stockPerformance[i][3], stockPerformance[i][4])
                stockData.push(stock_obj)
        }
        return  stockData;
    }
    return null;
}

function parseHelper(date, open, high, low, close, volume) {
  var stock = {};
  stock.date = date;
  stock.open = +open;
  stock.high = +high;
  stock.low = +low;
  stock.close = +close;
  stock.volume = +volume;
  return stock
}

export default parseData;
