import React from 'react';
import {Layout, Typography, Col, Row} from 'antd';
const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const BackTest = () => (
  <Layout className="layout">
    <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
      <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
        <Row>
          <Title level={3}>Backtest of the Haystak Engine</Title>
          <Col xs={24} md={12}>
            <Title level={4}>Below is a backtest of an automated trading strategy generated by the Haystak Engine.</Title>
            <Title level={4}>Strategy:</Title>
            <Paragraph>
              <ul>
                <li>Runs between <b>19th Aug 2015</b> and <b>24th Sep 2019</b></li>
                <li>Evaluates long and short trading opportunities once per day</li>
                <li>Trades the <b>220 equities</b> currently made available through the Haystak Engine</li>
                <li>Trades in patterns that have an aggregate <b>Haystak Similiarity Score</b> of at least <b>90.25</b> </li>
                <li>Trades in patterns where the <b>average following returns</b> of all matches are more significant than <b>1%</b></li>
                <li>Trades in patterns where at least <b>85%</b> of matches had following returns with the <b>same sign</b></li>
              </ul>
            </Paragraph>
            <Paragraph>
            </Paragraph>
            <Title level={4}>Performance:</Title>
            <Paragraph>
              <ul>
                <li>The strategy traded <b>5003</b> times</li>
                <li><b>2872</b> of these trades were profitable (equivalent to <b>57.4%</b> of all trades)</li>
                <li>The strategy achieved a positive total return of <b>46.6%</b> (9.83% annualized)</li>
                <li>Over the same period, the S&P 500 returned <b>42.6%</b> (9.09% annualized)</li>
              </ul>
            </Paragraph>
            <p><i style={{fontSize:"12px"}}>* Past performance does NOT guarantee future results</i></p>
          </Col>
          <Col xs={24} md={12}>
            <img src="backtest_haystak.png" width="100%" height="100%"></img>
          </Col>
        </Row>
      </div>
    </Content>
  </Layout>
)

export default BackTest;
