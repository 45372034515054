import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox, Layout, Row, Col} from 'antd';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../Constants/routes';
const { Content } = Layout;

const PasswordForgetPage = () => (
  <Layout>
    <Content style={{ padding: '28px 28px', minHeight: '70vh'}}>
      <div style={{ background: '#fff', padding: 28, minHeight: '70vh'}}>
        <Row justify="center" type="flex">
          <h2>Reset your password</h2>
        </Row>
        <br/>
        <Row justify="center" type="flex">
          <PasswordForgetForm />
        </Row>
      </div>
    </Content>
  </Layout>
);

const INITIAL_STATE = {
  email: '',
  error: null
};

class PasswordForgetFormBase extends React.Component{
  constructor(props){
    super(props)

    this.state = { ...INITIAL_STATE};
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then( () => {
        this.setState({ ...INITIAL_STATE})
      })
      .catch(error => {
        this.setState({error})
      })

    event.preventDefault();
  };

  onChange = event =>{
    this.setState({[event.target.name]: event.target.value});
  }

  render(){
    const {email, error} = this.state;
    const isInvalid = email ==='';

    return (
      <Form onSubmit={this.onSubmit} className="login-form">
        <Input
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <br/>
        <br/>
        <Button disabled={isInvalid} type="primary" htmlType="submit" className="login-form-button">
          Reset
        </Button>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot password</Link>
)

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase)
export {PasswordForgetForm, PasswordForgetLink}
